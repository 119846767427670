import { UserDataType } from "../../context/types";
import { ICustomer } from "../../models/ecomm/customer";
import { IProduct } from "../../models/ecomm/product";

// update product price with margin
export const updateProdcutPricing = (
  product: IProduct,
  customer: ICustomer,
  user: UserDataType | null
) => {
  const {
    price,
    retailMargin,
    retailCustomerMargin,
    wholesaleCustomerMargin,
  } = product;

  let updatedPrice = price; // unregistered customer
  let margin = retailMargin;

  if (user) {
    margin = retailCustomerMargin; // retail registered customer

    if (customer && customer.isWholesaleCustomer) {
      margin = wholesaleCustomerMargin;
    }
  }

  const { value, prefix } = margin
  if (prefix === "%") {
    const _margin = price * (value/100);
    updatedPrice += _margin
  } else {
    updatedPrice += value;
  }

  return { ...product, price: updatedPrice };
};