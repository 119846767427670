import {
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";

import { PrintSelect } from "../../comps/ecomm/print";
import { ProductSelect } from "../../comps/ecomm/product/productSelect";

export const renderPrint = (params: GridRenderCellParams) => {
  return <PrintSelect id="eighth_demo_step" params={params} />;
};

export const renderProduct = (params: GridRenderCellParams) => {
  return <ProductSelect id="sixth_demo_step" params={params} />;
};
export const ccyFormat = (num: number) => {
  return `${num.toFixed(2)}`;
};

export const getMeterSquared = (params: GridValueGetterParams) => {
  return (params.row.glassWidth * params.row.glassHeight) / 1000000;
};

export const getGlassWidth = (params: GridValueGetterParams) => {
  if (parseInt(params.row.glassWidth) < 200) return 200;
  return params.row.glassWidth;
};

export const importAllImages = (r: any) => {
  return r.keys().map((item: string) => {
    return {
      name: r(item),
      order: item.replace("./", "").replace(/\.(png|jpe?g)$/, ""), // remove file start
    };
  });
};
