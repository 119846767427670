import { useState } from 'react';
import { Button } from '@mui/material';
import { useAuth } from '../../hooks/useAuth';
import { useApp } from '../../context/app-context';
import { DeleteProfileDialog } from './deleteProfileDialog';

const DeleteAccountButton = () => {
  const { user, deleteUser } = useAuth();
  const { customer } = useApp();

  const [deleteProfileOpen, setDeletProfileOpen] = useState<boolean>(false);

  const toggleDeleteProfileDialog = () => setDeletProfileOpen((prev) => !prev);

  const handleDeleteProfile = async () => {
    await deleteUser(customer._id);
    toggleDeleteProfileDialog();
  };

  return (
    <>
      <Button
        onClick={toggleDeleteProfileDialog}
        variant="outlined"
        sx={{
          mr: "20px",
          textTransform: "none",
          color: "#344054",
          borderRadius: "8px",
          border: "1px solid #D0D5DD",
          boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",

          "&:hover": {
            border: "1px solid #D0D5DD",
          },
        }}
      >
        Delete My Account
      </Button>

      <DeleteProfileDialog
          open={deleteProfileOpen}
          handleClose={toggleDeleteProfileDialog}
          handleDelete={handleDeleteProfile}
          user={user}
        />
    </>
  );
}

export default DeleteAccountButton