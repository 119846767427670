import { useEffect } from "react";
import { IRowType } from "../../../../../../../../../models/ecomm/productTable";

// Custom hook to use debounced orderItemDimensions to update currentOrderItem

export function useUpdateCurrentOrderItem(
  currentOrderItem: IRowType | undefined,
  debouncedOrderItemDimensions: {
    width: number;
    height: number;
  },
  handleWallpaperDetailsUpdate: (data: any) => void,
) {
  useEffect(() => {
    if (currentOrderItem) {
      const { width, height } = debouncedOrderItemDimensions;
      const { glassWidth, glassHeight } = currentOrderItem;

      const updatedCurrentOrderItem = {
        ...currentOrderItem,
        glassWidth: width,
        glassHeight: height,
      };

      const isUpdateSafe = glassWidth !== width || glassHeight !== height;
      const isNotInitialValue = width + height !== 0;

      if (isNotInitialValue && isUpdateSafe) {
        handleWallpaperDetailsUpdate({
          currentOrderItem: updatedCurrentOrderItem
        })
      }
    }
  }, [currentOrderItem, debouncedOrderItemDimensions, handleWallpaperDetailsUpdate]);
}
