import React, { useState } from "react";

import { Box } from "@mui/material";
import { GridRenderCellParams, useGridApiContext } from "@mui/x-data-grid";

import { Print } from "./display-print";
import PrintDialog from "./print-dialog";

import { IFile, PrintTabSelectorType, VariobelColor } from "../../../models/ecomm/productTable";

import { useApp } from "../../../context/app-context";
import { Provider } from "react-redux";
import { store } from "./print-dialog/redux";

interface IPrintSelect {
  params: GridRenderCellParams;
  readOnly?: boolean;
  id?: string;
}

export const PrintSelect = ({ params, id: attributeID, readOnly = false }: IPrintSelect) => {
  const { id, value, field } = params;
  const [open, setOpen] = useState<boolean>(false);
  const [print, setPrint] = useState<{
    type: string;
    value: string | VariobelColor | IFile;
  }>(value);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tab, setTab] = useState<string>(value.type);
  const { rows, updateAppContext } = useApp();

  const apiRef = useGridApiContext();
  const handleClickOpen = () => {
    if (readOnly) return;
    setOpen(true);
  };

  const closeDialog = () => setOpen(false);

  const handlePrintSelect = (value: VariobelColor | string | IFile, tab: string) => {
    closeDialog();
    if (!value && tab === "") return;

    setPrint({ type: tab!, value: value });
    setTab(tab!);

    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        return {
          ...row,
          print: { type: tab!, value: value }
        }
      }

      return row;
    });

    // update print in context
    updateAppContext({
      rows: updatedRows,
    });

    apiRef.current.setEditCellValue({
      id,
      field,
      value: { type: tab!, value: value },
    });
  };

  return (
    <Box id={attributeID} display="flex" justifyContent="center" alignItems="center">
      {/* display selected prop */}
      <Print print={print} onClick={handleClickOpen} />

      <Provider store={store}>
        <PrintDialog
          selectedValue={print.value}
          selectedTab={print.type as PrintTabSelectorType}
          selectedID={id}
          open={open}
          closeDialog={closeDialog}
          handleSelect={handlePrintSelect}
        />
      </Provider>
    </Box>
  );
};
