import React, {
  useEffect,
  useRef,
  useState
} from "react";
import {
  Backdrop,
  Box,
  Button, CircularProgress, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Icon,
  IconButton
} from "@mui/material";
import axios from "axios";
import JsPdf from "jspdf";
import html2canvas from "html2canvas";
import { IOrder } from "../../models/ecomm/order";

interface Props {
  open: boolean;
  handleClose: () => void;
  order: IOrder;
}

export const OrderInvoiceDialog = (props: Props) => {
  const { open, handleClose, order } = props;

  const PreviewRef = useRef<HTMLDivElement>(null);

  const [company, setCompany] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [invoiceData, setInvoiceData] = useState("");
  const [isDownloading, setIsSDownloading] = useState(false);

  // handle company fetch
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_HOST}/company/get/${process.env.REACT_APP_COMPANY_ID}`,
        { withCredentials: true }
      )
      .then((res) => {
        const { company: _company } = res.data;
        setCompany(_company);
      })
      .catch((err) => console.log("err fetching company", err));
  }, []);

  // handle invoice generation
  useEffect(() => {
    if (order && company) {
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_SERVER_HOST}/order/get-invoice`,
        data: {
          data: order,
          company,
        },
        withCredentials: true,
      };

      (async () => {
        await axios(config)
          .then((res) => setInvoiceData(res.data))
          .catch((err) => console.log("error generating invoice", err));
      })();
    }
  }, [company, order]);

  // handle loading
  useEffect(() => {
    if (invoiceData.replace(/\s/g, "").length) {
      setIsLoading(false);
    }
  }, [invoiceData]);

  const handleDownloadPdf = async () => {
    if (PreviewRef.current) {
      const ref = PreviewRef.current as any;

      setIsSDownloading(true);

      await html2canvas(ref, {
        logging: false,
        useCORS: true,
        scale: 0.8,
      })
        .then((canvas) => {
          const imgData = canvas.toDataURL();
          const pdf = new JsPdf();
          pdf.addImage(imgData, "JPEG", 0, 0, 0, 0);
          pdf.save(`order-${order.orderNumber}.pdf`);
        })
        .catch((err) => console.log(err));

      setIsSDownloading(false);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      scroll="body"
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        <IconButton
          size="small"
          onClick={handleClose}
          sx={{ position: "absolute", right: "1rem", top: "1rem" }}
        >
          <Icon>close</Icon>
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        {isLoading && (
          <Box
            sx={{
              minHeight: "200px",
            }}
          >
            <Backdrop open={isLoading}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        )}

        {!isLoading && (
          <div
            ref={PreviewRef}
            dangerouslySetInnerHTML={{ __html: invoiceData }} />
        )}
      </DialogContent>

      <DialogActions>
        <Button variant="contained">Print</Button>

        <Button
          variant="contained"
          color="success"
          disabled={isDownloading}
          onClick={handleDownloadPdf}
          sx={{ gap: 1 }}
        >
          {isDownloading && (
            <CircularProgress
              color="inherit"
              sx={{
                width: "20px !important",
                height: "20px !important",
              }} />
          )}
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
};
