import { Paper } from "@mui/material";
import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { IFile } from "../../../../../../../models/ecomm/productTable";
import { useDispatch } from "react-redux";
import { updateLocalWallpaperState } from "../../../redux";

interface Props {
  wallpaperFile: IFile;
  selected: boolean;
}

export const WallpaperImage = (props: Props) => {
  const { wallpaperFile, selected } = props;
  const { name } = wallpaperFile;

  const theme = useTheme();
  const dispatch = useDispatch();

  const [isHover, setIsHover] = useState(false);
  const elevation = isHover ? 5 : 1;

  const handleMouseEnter = () => setIsHover(true);
  const handleMouseLeave = () => setIsHover(false);
  const handleWallpaperChange = () => {

    dispatch(updateLocalWallpaperState({
      wallpaper: wallpaperFile
    }));
  };

  return (
    <Paper
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleWallpaperChange}
      elevation={elevation}
      sx={[
        {
          width:  "100",
          height: { xs: 70, md: 120 },
          ":hover": {
            cursor: "pointer",
          },
          background: `url(${name})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
        },
        selected && {
          border: `3px solid ${theme.palette.primary.main}`,
        },
      ]}
    />
  );
};
