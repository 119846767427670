import { TabList, TabListProps } from '@mui/lab';
import React from 'react'

const TabSelector = (props: TabListProps) => {
  const { sx, ...rest } = props;

  return (
    <TabList
      sx={{
        border: "1px solid #C6D2D8",
        borderRadius: 2,

        "& .MuiTab-root": {
          textTransform: "none",
          fontWeight: 600,
          p: "8px 16px",

          "&.Mui-selected": {
            backgroundColor: (theme) => theme.palette.secondary.light,
          }
        },

        "& .MuiTabs-indicator": {
          height: 0,
        },

        ...sx
      }}
      {...rest}
    />
  );
};

export default TabSelector;