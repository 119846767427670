import { ContactScreen } from "../screens/contact";
import { EcommScreen } from "../screens/ecomm/ecomm";
import { CheckoutScreen } from "../screens/ecomm/ecomm-checkout";
import { SuccessScreen } from "../screens/ecomm/ecomm-success";
import { FaqScreen } from "../screens/faq";
import { HomeScreen } from "../screens/home";
import { LoginScreen } from "../screens/login";
import { SignupScreen } from "../screens/signup";
import ProfileScreen from "../screens/profile";
import NotFoundScreen from "../screens/notFound";
import VerifyCustomerScreen from "../screens/verifyCustomer";
import ForgotPasswordScreen from "../screens/ecomm/forgot-password";

export interface AppRouteType {
  to: string;
  element: React.ComponentType;
  useLayout?: boolean;
  ecomm?: boolean;
}

export const appRoutes: AppRouteType[] = [
  {
    to: "/",
    element: HomeScreen,
  },
  {
    to: "/faq",
    element: FaqScreen,
  },
  {
    to: "/kontakt",
    element: ContactScreen,
  },
  {
    to: "/objednavka-odoslana",
    element: SuccessScreen,
  },
  {
    to: "/prihlasenie",
    element: LoginScreen,
  },
  {
    to: "/registracia",
    element: SignupScreen,
  },
  {
    to: "/profile",
    element: ProfileScreen,
  },

  {
    to: "/verify",
    element: VerifyCustomerScreen,
  },
  {
    to: "/passwordReset",
    element: ForgotPasswordScreen,
  },

  {
    to: "/obchod",
    element: EcommScreen,
    ecomm: true,
  },
  {
    to: "/obchod/dokoncit-objednavku",
    element: CheckoutScreen,
    ecomm: true,
  },


  {
    to: "*",
    element: NotFoundScreen,
    useLayout: false,
  },
];
