import { Icon, Box, Typography } from "@mui/material";
import { useAuth } from "../../hooks/useAuth";
import { getInitials } from "../../helpers/getInitials";

const ProfileIcon = () => {
  const { user } = useAuth();

  if (!user) {
    return (
      <Icon
        sx={{
          fontSize: "2rem",
          color: "#383637",
        }}
      >
        account_circle
      </Icon>
    );
  }

  return (
    <Box
      sx={{
        width: 32,
        height: 32,
        background: "#383637",
        borderRadius: "100%",
        display: "grid",
        placeItems: "center",
      }}
    >
      <Typography
        sx={{
          color: "white",
        }}
      >
        {getInitials(user.firstName)}
      </Typography>
    </Box>
  );
};

export default ProfileIcon;
