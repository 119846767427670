import { Box, Icon, Typography } from "@mui/material";

interface Props {
  value: number;
  icon: string;
  label: string;
}

const HeaderCard = (props: Props) => {
  const { value, icon, label } = props;

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: { md: "column" },
        alignItems: { xs: "center", md: "flex-start" },
        justifyContent: "space-between",

        width: { xs: "100%", md: "300px" },
        height: { xs: "60px", md: "164px" },
        padding: "14px 23px",
        gap: "10px",

        borderRadius: "8px",
        border: `2px solid ${theme.palette.secondary.light}`,
      })}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "17px" }}>
        <Box
          sx={(theme) => ({
            width: { xs: 24, md: 47 },
            height: { xs: 24, md: 47 },
            background: {
              xs: "transparent",
              md: theme.palette.secondary.light,
            },
            borderRadius: "100%",
            display: "grid",
            placeItems: "center",
          })}
        >
          <Icon>{icon}</Icon>
        </Box>

        <Typography>{label}</Typography>
      </Box>

      <Typography sx={{ fontSize: { md: "1.5rem" }, fontWeight: { xs: 400, md: 600 } }}>
        {value}
      </Typography>
    </Box>
  );
};

export default HeaderCard;
