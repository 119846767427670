import { useEffect } from "react";
import { getImageDimensions } from "../../../helpers";
import { IRowType } from "../../../../../../../../../models/ecomm/productTable";

// Custom hook to control alert warning of selected image resolution for print

export function useImageResolutionWarning(
  type: "custom" | "manual",
  preview: File | undefined,
  rows: IRowType[],
  handleWallpaperDetailsUpdate: (data: any) => void,
) {
  useEffect(() => {
    if (type === "custom" && preview) {
      const checkDimensions = async () => {
        const idealHeight = 5000;
        const idealDPI = 300;

        const selectedImageDimensions = await getImageDimensions(
          preview as File
        );
        const isUsable = selectedImageDimensions.height >= idealHeight &&
          selectedImageDimensions.DPI >= idealDPI;

        handleWallpaperDetailsUpdate({
          isSelectedImageClear: isUsable
        })

      };

      checkDimensions();
    }
  }, [type, preview, rows, handleWallpaperDetailsUpdate]);
}
