import * as React from "react";
import ModalMui from "@mui/material/Modal";
import { Paper, Button, Typography, Stack } from "@mui/material";

interface IModal {
  open: boolean;
  setOpen: (open: boolean) => void;
  header: string;
  description?: string;
  confirmLabel: string;
  cancelLabel: string;
  onConfirm: () => void;
  onCancel?: () => void;
}
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  p: 4,
};
export const Confirm = ({
  open,
  setOpen,
  header,
  description = "",
  confirmLabel,
  cancelLabel,
  onConfirm,
  onCancel = () => {},
}: IModal) => {
  return (
    <ModalMui
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {header}
        </Typography>
        {description && (
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {description}
          </Typography>
        )}
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          sx={{ mt: 3, py: 2 }}
        >
          <Button
            variant="contained"
            onClick={() => {
              onConfirm();
              setOpen(false);
            }}
          >
            {confirmLabel}
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              onCancel();
              setOpen(false);
            }}
          >
            {cancelLabel}
          </Button>
        </Stack>
      </Paper>
    </ModalMui>
  );
};
