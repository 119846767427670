import {
  Button, alpha, styled
} from "@mui/material";

export const TabSubmitButton = styled(Button)(({ theme}) => ({
  minWidth: "122px",
  textTransform: "none",
  color: "white",
  borderRadius: theme.spacing(1),
  boxShadow: "none",
  padding: "8px 24px",
  "&:hover": {
    boxShadow: "none",
    backgroundColor: alpha(theme.palette.secondary.main, 0.95),
  },

  "&.MuiButton-outlined": {
    color: theme.palette.secondary.main,
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "transparent",
    },
  },

  "&.Mui-disabled": {
    color: "rgba(0, 0, 0, 0.26)"
  },
})) as typeof Button;
