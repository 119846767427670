/* eslint-disable no-template-curly-in-string */
import { forwardRef, useEffect } from "react";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { CountryDropdown } from "./country-dropdown";
import { useApp } from "../../../context/app-context";
import {
  ICountry,
  ICustomer,
  ICustomerAddress,
} from "../../../models/ecomm/customer";
import * as yup from "yup";
import { Formik, FormikErrors, FormikProps } from "formik";

interface IAddressForm {
  language?: string;
}

yup.setLocale({
  mixed: {
    // eslint-disable-next-line no-template-curly-in-string
    required: "${label} je povinný údaj",
  },
  number: {
    // eslint-disable-next-line no-template-curly-in-string
    integer: "${label} je povinný údaj",
  },
});

const CountrySchema = yup.object().shape({
  code: yup.string().required("Prosím zadajte krajinu").label("Adresa riadok"),
  label: yup.string().required("Prosím zadajte krajinu"),
  phone: yup.string().required("Prosím zadajte krajinu"),
});

const AddressSchema = yup.object().shape({
  company: yup.string(),
  address: yup.string().required("Prosím zadajte addresu"),
  city: yup.string().required().label("Mesto"),
  postcode: yup
    .number()
    .positive()
    .integer("${label} musí byť číslo")
    .required("${label} je povinný údaj")
    .nullable()
    .label("PSČ"),
  country: CountrySchema.required("${label} je povinný údaj")
    .nullable()
    .label("Krajina"),
});

const schema = yup.object().shape({
  firstName: yup.string().required().label("Meno"),
  lastName: yup.string().required().label("Priezvisko"),
  email: yup.string().email().required().label("Email"),

  billingAddress: yup.array().of(AddressSchema),
});

export type FormikRef = FormikProps<ICustomer>;

export const OrderAddressForm = forwardRef<FormikRef>(
  ({ language = "sk" }: IAddressForm, ref) => {
    const { customer, updateAppContext } = useApp();

    // Fill form on initial render
    useEffect(() => {
      const _customer = JSON.parse(
        sessionStorage.getItem("userData") as string
      );

      if (_customer) {
        updateAppContext({ customer: _customer });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Formik
        innerRef={ref}
        enableReinitialize={true}
        initialValues={customer}
        validationSchema={schema}
        onSubmit={() => undefined}
      >
        {({ handleChange, values, setFieldValue, errors }) => (
          <form style={{ width: "100%" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  label="Zadajte názov"
                  id={`firstName`}
                  name="firstName"
                  fullWidth
                  autoComplete={`first name`}
                  variant="standard"
                  error={Boolean(errors.firstName)}
                  value={values.firstName}
                  onChange={(e) => {
                    handleChange(e);
                    updateAppContext({
                      customer: { ...customer, firstName: e.target.value },
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  label="Priezvisko"
                  id={`lastName`}
                  name={`lastName`}
                  fullWidth
                  autoComplete={`last name`}
                  variant="standard"
                  value={values.lastName}
                  error={Boolean(errors.lastName)}
                  onChange={(e) => {
                    handleChange(e);
                    updateAppContext({
                      customer: { ...customer, lastName: e.target.value },
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id={`email`}
                  name={`email`}
                  label="Email"
                  type="email"
                  fullWidth
                  autoComplete={`email`}
                  variant="standard"
                  value={values.email}
                  error={Boolean(errors.email)}
                  onChange={(e) => {
                    handleChange(e);
                    updateAppContext({
                      customer: { ...customer, email: e.target.value },
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id={`$phone`}
                  name={`phone`}
                  label="Telefón"
                  fullWidth
                  autoComplete={`phone`}
                  variant="standard"
                  value={values.phone}
                  error={Boolean(errors.phone)}
                  onChange={(e) => {
                    handleChange(e);
                    updateAppContext({
                      customer: { ...customer, phone: e.target.value },
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id={`company`}
                  name={`billingAddress[0].company`}
                  label="Názov spoločnosti"
                  fullWidth
                  autoComplete={`company`}
                  variant="standard"
                  value={values.billingAddress[0].company}
                  error={Boolean(
                    errors.billingAddress &&
                      (
                        errors
                          .billingAddress[0] as FormikErrors<ICustomerAddress>
                      ).company
                  )}
                  onChange={(e) => {
                    handleChange(e);
                    const company = e.target.value;
                    const billingAddress = [...customer.billingAddress];

                    billingAddress[0] = {
                      ...billingAddress[0],
                      company,
                    };

                    updateAppContext({
                      customer: { ...customer, billingAddress },
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  id={`billingAddress[0].address`}
                  name={`billingAddress[0].address`}
                  label="Adresa"
                  fullWidth
                  autoComplete="billing street-address"
                  variant="standard"
                  value={values.billingAddress[0].address}
                  error={Boolean(
                    errors.billingAddress &&
                      (
                        errors
                          .billingAddress[0] as FormikErrors<ICustomerAddress>
                      ).address
                  )}
                  onChange={(e) => {
                    handleChange(e);
                    const address = e.target.value;
                    const billingAddress = [...customer.billingAddress];

                    billingAddress[0] = {
                      ...billingAddress[0],
                      address,
                    };

                    updateAppContext({
                      customer: { ...customer, billingAddress },
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id={`billingAddress[0].city`}
                  name={`billingAddress[0].city`}
                  label="Mesto"
                  fullWidth
                  autoComplete="shipping address-level2"
                  variant="standard"
                  value={values.billingAddress[0].city}
                  error={Boolean(
                    errors.billingAddress &&
                      (
                        errors
                          .billingAddress[0] as FormikErrors<ICustomerAddress>
                      ).city
                  )}
                  onChange={(e) => {
                    handleChange(e);
                    const city = e.target.value;
                    const billingAddress = [...customer.billingAddress];

                    billingAddress[0] = {
                      ...billingAddress[0],
                      city,
                    };

                    updateAppContext({
                      customer: { ...customer, billingAddress },
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id={`billingAddress[0].postcode`}
                  name={`billingAddress[0].postcode`}
                  label="PSČ"
                  fullWidth
                  autoComplete="shipping postal-code"
                  variant="standard"
                  value={values.billingAddress[0].postcode}
                  error={Boolean(
                    errors.billingAddress &&
                      (
                        errors
                          .billingAddress[0] as FormikErrors<ICustomerAddress>
                      ).postcode
                  )}
                  onChange={(e) => {
                    handleChange(e);
                    const postcode = e.target.value;
                    const billingAddress = [...customer.billingAddress];

                    billingAddress[0] = {
                      ...billingAddress[0],
                      postcode,
                    };

                    updateAppContext({
                      customer: { ...customer, billingAddress },
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <CountryDropdown
                  onChange={(country: ICountry) => {
                    setFieldValue("billingAddress[0].country", country);
                    const billingAddress = [...customer.billingAddress];

                    billingAddress[0] = {
                      ...billingAddress[0],
                      country,
                    };

                    updateAppContext({
                      customer: { ...customer, billingAddress },
                    });
                  }}
                  countryValue={values.billingAddress[0].country}
                  error={Boolean(
                    errors.billingAddress &&
                      (
                        errors
                          .billingAddress[0] as FormikErrors<ICustomerAddress>
                      ).country
                  )}
                  language={language}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    );
  }
);
