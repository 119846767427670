import { useEffect } from "react";
import { IRowType } from "../../../../../../../../../models/ecomm/productTable";

// Custom hook to set current order item being edited

export function useCurrentOrderItem(
  rows: IRowType[],
  selectedID: string | number,
  handleWallpaperDetailsUpdate: (data: any) => void,
) {
  useEffect(() => {
    const _currentRow = rows.find((row) => row.id === selectedID);
    if (_currentRow) {
      handleWallpaperDetailsUpdate({
        currentOrderItem: _currentRow
      })
    }
  }, [rows, selectedID, handleWallpaperDetailsUpdate]);
}
