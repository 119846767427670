import {
  Box,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useInView } from "react-cool-inview";
import "intersection-observer";
import DesktopNav from "./desktop";
import MobileNav from "./mobile";
import Topbar from "./topbar";


const navItems = [
  { title: "Obchod", path: "/obchod" },
  { title: "FAQ", path: "/faq" },
  { title: "Kontakt", path: "/kontakt" },
];

const noAuthNavItems = [
  { title: "Login", path: "/prihlasenie" },
  { title: "Register", path: "/registracia" },
]

export const NavBar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { observe, inView }  = useInView();

  return (
    <Box
      component="header"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flexShrink: 0,
        zIndex: 1001,
        mb: { sm: 1 },
      }}
    >
      {!isMobile && <Topbar ref={observe} navItems={noAuthNavItems} />}

      {/*
        Insert spacing on top of content, when:
          not displaying top bar in desktop view
          also, when in mobile view; inView by default is "false"
      */}
      {!inView && <Toolbar />}

      {!isMobile && <DesktopNav {...{ inView, navItems }} />}

      {isMobile && <MobileNav {...{ navItems, noAuthNavItems }} />}
    </Box>
  );
};
