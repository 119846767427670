import {
  Box,
  // Button,
  Divider,
  Grid,
  Stack,
  // styled,
  // TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { PageContainer } from "./pageContainer";

interface IFooterColumnContent {
  title?: string;
  text: string;
  link?: string;
  component?: React.ReactElement;
}
interface IFooterColumn {
  header: string;
  content: IFooterColumnContent[];
}
// const CssTextField = styled(TextField)(({ theme }) => ({
//   "& .MuiOutlinedInput-root": {
//     color: "white",
//     background: theme.palette.secondary.light,
//     fontSize: "14px",
//     "&.Mui-focused fieldset": {
//       // - Set the Input border when parent is focused
//       borderColor: theme.palette.secondary.dark,
//     },
//     "& input::placeholder": {
//       color: "white",
//     },
//   },
// }));
// const ButtonPrimary = styled(Button)(({ theme }) => ({
//   width: "fit-content",
//   fontSize: "14px",
//   background: theme.palette.secondary.light,
//   color: "white",

//   ":hover": {
//     background: theme.palette.secondary.dark,
//     color: "white",
//   },
// }));
const columns: IFooterColumn[] = [
  {
    header: "Kontakt",
    content: [
      {
        title: "Adresa",
        text: "VARIODOOR s.r.o., Stara Prievozska 1349/2, 821 09, Bratislava",
      },
      {
        title: "Telefón",
        text: "+421 948 997 914",
      },
      {
        title: "Email",
        text: "info@variobel.sk",
        link: "mailto:info@variobel.sk",
      },
      {
        title: "Pracovná doba",
        text: "Po - Pia / 9:00 - 17:00",
      },
    ],
  },
  {
    header: "Mapa stránky",
    content: [
      {
        text: "Pomocník a časté otázky",
        link: "/faq",
      },
      {
        text: "Kontakt",
        link: "/kontakt",
      },
      // {
      //   text: "Orders History",
      //   link: "/",
      // },
      // {
      //   text: "Advanced Search",
      //   link: "/",
      // },
      // {
      //   text: "My Account",
      //   link: "/",
      // },
      // {
      //   text: "Careers",
      //   link: "/",
      // },
      // {
      //   text: "About Us",
      //   link: "/",
      // },
      // {
      //   text: "Corporate Sales",
      //   link: "/",
      // },
      // {
      //   text: "Privacy",
      //   link: "/",
      // },
    ],
  },
  // {
  //   header: "Prihlásenie sa k odberu",
  //   content: [
  //     {
  //       text: "Najnovšie informácie o produktoch, akciach. Prihlásiť sa do odberu noviniek:",
  //       component: (
  //         <Button fullWidth size="large" sx={{ mt: 2 }} variant="contained">
  //           Prihlásiť k odberu noviniek
  //         </Button>
  //       ),
  //     },
  //   ],
  // },
];

export const Footer = () => {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          background: "#f7f7f7",
        }}
      >
        <PageContainer
          sx={{
            color: "#999",
            my: 0
          }}
        >
          {/* top */}
          <Grid
            container
            py={4}
            px={{ xs: 2, md: 3 }}
            spacing={{ xs: 3, md: 0 }}
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item gap={1} xs={12} md={3}>
              <Stack direction="column">
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 600 }}
                  textTransform="uppercase"
                  color={theme.palette.primary.dark}
                >
                  VARIOBEL
                </Typography>
                <Typography variant="body2" color={theme.palette.primary.light}>
                  Farebné výplne dverí pre Váš krajší nábytok. Všetko na mieru
                  podľa požiadaviek. Exkluzívne iba u nás.
                </Typography>
                <Typography
                  variant="body2"
                  textTransform="uppercase"
                  color={theme.palette.primary.dark}
                  sx={{ mt: 2 }}
                >
                  Máte otázky
                </Typography>
                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={2}
                  sx={{ mt: 1 }}
                >
                  <Typography
                    variant="h6"
                    color={theme.palette.primary.dark}
                    sx={{ fontWeight: 700 }}
                  >
                    +421 948 997 914
                  </Typography>
                  {/* <Typography
                    variant="h6"
                    color={theme.palette.primary.dark}
                    sx={{ fontWeight: 700 }}
                  >
                    09809 098 879
                  </Typography> */}
                </Stack>
              </Stack>
            </Grid>
            {columns.map((column, i) => (
              <Grid
                key={column.header}
                item
                gap={1}
                xs={12}
                md={3}
                flexDirection="column"
                display="flex"
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 600 }}
                  textTransform="uppercase"
                  color={theme.palette.primary.dark}
                >
                  {column.header}
                </Typography>

                {column.content.map(
                  (
                    { title, text, link, component }: IFooterColumnContent,
                    i
                  ) => (
                    <Box key={i}>
                      {title && (
                        <Typography variant="caption" textTransform="uppercase">
                          {title}
                        </Typography>
                      )}
                      <Typography
                        variant="body2"
                        color={theme.palette.primary.light}
                      >
                        {link ? (
                          <Box
                            component={Link}
                            to={link}
                            sx={{
                              color: "#005486",
                              fontSize: { xs: "default", sm: "0.875rem" },
                              textDecoration: "none",
                            }}
                          >
                            {text}
                          </Box>
                        ) : (
                          text
                        )}
                      </Typography>
                      {component && <>{component}</>}
                    </Box>
                  )
                )}
              </Grid>
            ))}
          </Grid>
        </PageContainer>
      </Box>

      <Box
        py={4}
        sx={{
          backgroundColor: theme.palette.primary.dark,
        }}
      >
        <PageContainer sx={{ my: 0}}>
          <Typography variant="body2" color="white">
            &copy; Variobel s.r.o. {new Date().getFullYear()}. All Rights
            Reserved.
          </Typography>
        </PageContainer>
      </Box>
    </>
  );
};
