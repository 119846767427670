import { CSSProperties } from "react";
import { MapContainer, Marker, Tooltip, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css";
import "leaflet-defaulticon-compatibility";

interface IMapProps {
  mapCenter: [number, number];
  markers: { position: [number, number]; tooltip: string }[];
  style: CSSProperties;
}

export const Map = ({ mapCenter, markers, style }: IMapProps) => {
  return (
    <MapContainer
      center={mapCenter} // Hamburg coordinates
      zoom={16}
      scrollWheelZoom={false}
      style={style}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {markers.map((marker, index) => (
        <Marker key={index} position={marker.position}>
          <Tooltip>{marker.tooltip}</Tooltip>
        </Marker>
      ))}
    </MapContainer>
  );
};
