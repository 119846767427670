import React, { useState, Dispatch, SetStateAction, useEffect } from "react";
import {
  AutocompleteRenderInputParams,
  Box,
  Button,
  CircularProgress,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  alpha,
} from "@mui/material";
import {
  TextField as FormikTextField,
  Autocomplete as FormikAutocomplete,
  CheckboxWithLabel as FormikCheckboxWithLabel,
} from "formik-mui";
import { Field, Form, Formik, FormikHelpers, useFormikContext } from "formik";
import { CountryType, supportedCountries } from "../../helpers/ecomm/countries";
import { schemaType, validationSchema } from "../../models/profile";
import { useAuth } from "../../hooks/useAuth";
import { useApp } from "../../context/app-context";
import { ConnectedFocusError } from "focus-formik-error";
import { useSnackbar } from "notistack";

interface Props {
  initialValues: schemaType;
  isCompanyTab: boolean;

  // Added as prop to allow company account checkbox alter disabled tab
  setEditableValues: Dispatch<SetStateAction<any>>;
}

// This is used to update the disabled state of the company tab
const HasCompanyDataHelper = ({ setEditableValues }: {
  setEditableValues: Dispatch<SetStateAction<any>>;

}) => {
  const { values } = useFormikContext<schemaType>();

  useEffect(() => {
    setEditableValues(values);
  }, [values, setEditableValues]);

  return null;
}

const UserProfileDetailsTab = (props: Props) => {
  const { initialValues, isCompanyTab, setEditableValues } = props;

  const {editUser } = useAuth();
  const { customer } = useApp();
  const { enqueueSnackbar } = useSnackbar();


  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleEdit = async (data: Partial<schemaType>) => {
    await editUser(customer._id, data);
  };

  const onSubmit = async (
    values: schemaType,
    formikHelpers: FormikHelpers<schemaType>
  ) => {
    const { setSubmitting } = formikHelpers;

    // these deletes are due to consent dates update in server
    delete values.gdprConsent;
    if (values.marketingConsent === initialValues.marketingConsent) {
      delete values.marketingConsent;
    }

    const clearSpace = (str: string) => str.replace(/\s/g, "");
    const isEmpty = clearSpace(String(values.password)).length === 0;

    // prevents empty string from being sent for update
    if (isEmpty || !values.password) {
      delete values.password;
    }

    // field is only used for UI
    // @ts-ignore
    delete values.hasCompanyData;

    try {
      await handleEdit(values);
      enqueueSnackbar("Profil aktualizovaný", { variant: "success" });
    } catch(error) {
      enqueueSnackbar("Niekde nastala chyba", { variant: "warning" });
    }

    setSubmitting(false);
  };

  // Note: even though `initialValues` can be set by `setEditableValues` it is fine
  // This is because formik by default prevents re-initialization
  const formikConfig= {
    validationSchema,
    initialValues,
    onSubmit,
  };

  return (
    <Formik {...formikConfig}>
      {({ isSubmitting, submitForm, touched, errors, values }) => (
        <Form>
          <ConnectedFocusError />

          <Grid
            container
            spacing={5}
            sx={{
              "& .MuiOutlinedInput-input, .MuiAutocomplete-root .MuiOutlinedInput-root":
                {
                  padding: "13px 16px",
                },

              "& .MuiInputLabel-root": {
                fontWeight: 600,
                fontSize: "0.9rem",
                mb: "10px",
              },
            }}
          >
            {!isCompanyTab && (
              <>
                <Grid item xs={12} md={3}>
                  <InputLabel htmlFor="firstName">First Name</InputLabel>
                  <Field
                    id="profile-firstName"
                    component={FormikTextField}
                    size="small"
                    name="firstName"
                    autoComplete="off"
                    type="text"
                    placeholder="First Name"
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <InputLabel htmlFor="lastName">Last Name</InputLabel>
                  <Field
                    id="profile-lastName"
                    component={FormikTextField}
                    size="small"
                    name="lastName"
                    autoComplete="off"
                    type="text"
                    placeholder="Last Name"
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <InputLabel htmlFor="email">Email</InputLabel>
                  <Field
                    id="profile-email"
                    component={FormikTextField}
                    size="small"
                    disabled
                    name="email"
                    autoComplete="off"
                    type="email"
                    placeholder="Email"
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Field
                    id="profile-password"
                    component={FormikTextField}
                    size="small"
                    name="password"
                    placeholder="Password"
                    autoComplete="new-password"
                    fullWidth
                    required
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? (
                              <Icon>visibility</Icon>
                            ) : (
                              <Icon>visibility_off</Icon>
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <InputLabel htmlFor="phone">Phone</InputLabel>
                  <Field
                    id="profile-phone"
                    component={FormikTextField}
                    size="small"
                    name="phone"
                    autoComplete="off"
                    type="phone"
                    placeholder="Phone"
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <InputLabel htmlFor="address">Address</InputLabel>
                  <Field
                    id="profile-address"
                    component={FormikTextField}
                    size="small"
                    name="billingAddress[0].address"
                    autoComplete="off"
                    type="text"
                    placeholder="Address"
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <InputLabel htmlFor="postcode">Post Code</InputLabel>
                  <Field
                    id="profile-postcode"
                    component={FormikTextField}
                    size="small"
                    name="billingAddress[0].postcode"
                    autoComplete="off"
                    type="text"
                    placeholder="Postcode"
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <InputLabel htmlFor="city">City</InputLabel>
                  <Field
                    id="profile-city"
                    component={FormikTextField}
                    size="small"
                    name="billingAddress[0].city"
                    autoComplete="off"
                    type="text"
                    placeholder="City"
                    fullWidth
                    required
                  />
                </Grid>

                {values["billingAddress"]!.length > 0 && (
                  <Grid item xs={12} md={3}>
                    <InputLabel htmlFor="country">Country</InputLabel>
                    <Field
                      id="profile-country"
                      disabled
                      name="billingAddress[0].country"
                      component={FormikAutocomplete}
                      options={supportedCountries}
                      getOptionLabel={(option: CountryType) => option.label}
                      renderInput={(params: AutocompleteRenderInputParams) => {
                        const flag = supportedCountries.find((country) => {
                          const _country =
                            values["billingAddress"]?.at(0)?.country;
                          return country.label === _country.label;
                        }) as CountryType;

                        return (
                          <TextField
                            {...params}
                            size="small"
                            name="billingAddress[0].country"
                            error={
                              touched["billingAddress[0].country"] &&
                              !!errors["billingAddress[0].country"]
                            }
                            helperText={errors["billingAddress[0].country"]}
                            placeholder="Country"
                            variant="outlined"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img
                                    loading="lazy"
                                    width="40"
                                    src={`https://flagcdn.com/w20/${flag?.code.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/w40/${flag?.code.toLowerCase()}.png 2x`}
                                    alt=""
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        );
                      }}
                      renderOption={(
                        props: React.HTMLAttributes<HTMLLIElement>,
                        option: CountryType
                      ) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            alt=""
                          />
                          {option.label} ({option.code}) +{option.phone}
                        </Box>
                      )}
                    />
                  </Grid>
                )}

                <Grid
                  item
                  container
                  xs={12}
                  sx={(theme) => ({
                    "& .MuiCheckbox-root": {
                      "&.Mui-checked": {
                        color: theme.palette.secondary.main,
                      },
                      "&.Mui-disabled": {
                        color: alpha(theme.palette.secondary.main, 0.26),
                      },
                    },

                    svg: {
                      borderRadius: "100%",
                    },
                  })}
                >
                  <Grid item xs={12} md={12}>
                    <Field
                      component={FormikCheckboxWithLabel}
                      type="checkbox"
                      name="hasCompanyData"
                      Label={{ label: "Company Account" }}
                    />
                    <HasCompanyDataHelper {...{ setEditableValues }} />
                  </Grid>

                  <Grid item xs={12} md="auto">
                    <Field
                      component={FormikCheckboxWithLabel}
                      type="checkbox"
                      name="gdprConsent"
                      disabled
                      Label={{ label: "GDPR" }}
                    />
                  </Grid>

                  <Grid item xs={12} md="auto">
                    <Field
                      component={FormikCheckboxWithLabel}
                      type="checkbox"
                      name="marketingConsent"
                      Label={{ label: "Marketing consent" }}
                    />
                  </Grid>
                </Grid>
              </>
            )}

            {isCompanyTab && (
              <>
                <Grid item xs={12} md={3}>
                  <InputLabel htmlFor="company">Meno spoločnosti</InputLabel>
                  <Field
                    id="profile-company"
                    component={FormikTextField}
                    name="company.name"
                    autoComplete="off"
                    type="text"
                    placeholder="Meno spoločnosti"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <InputLabel htmlFor="ico">ICO</InputLabel>
                  <Field
                    id="profile-ico"
                    component={FormikTextField}
                    name="company.ico"
                    autoComplete="off"
                    type="text"
                    placeholder="ICO"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <InputLabel htmlFor="dic">DIC</InputLabel>
                  <Field
                    id="profile-dic"
                    component={FormikTextField}
                    name="company.dic"
                    autoComplete="off"
                    type="text"
                    placeholder="DIC"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <InputLabel htmlFor="icdph">ICDPH</InputLabel>
                  <Field
                    id="profile-icdph"
                    component={FormikTextField}
                    name="company.icdph"
                    autoComplete="off"
                    type="text"
                    placeholder="ICDPH"
                    fullWidth
                  />
                </Grid>
              </>
            )}

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "flex-start" },
              }}
            >
              <Button
                variant="contained"
                disabled={isSubmitting}
                onClick={submitForm}
                startIcon={
                  isSubmitting ? (
                    <CircularProgress
                      style={{ color: "white", width: 22, height: 22 }}
                    />
                  ) : null
                }
                sx={{
                  textTransform: "none",
                  color: "white",
                  boxShadow: "none",
                  // padding: "14px 24px",
                  borderRadius: "8px",
                  backgroundColor: "#0E79B7",

                  ":hover": {
                    boxShadow: "none",
                    backgroundColor: "#0E79B7",
                  },
                }}
              >
                Save & Update
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default UserProfileDetailsTab;
