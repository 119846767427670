import { Typography, Stack, Grid } from "@mui/material";
import FaqAccordion from "../comps/faqAccordion";
import { PageContainer } from "../comps/pageContainer";

const faqs = [
  {
    heading: "Čo je to VARIOBEL®?",
    content:
      "VARIOBEL® je farebné 4mm sklo s nekonečnými farebnými variáciami, ktoré vyrábame nami vyvinutou špeciálnou technológiou na mieru podľa požiadaviek zákazníka. VARIOBEL® v našom prípade používame ako výplň posuvných (otváracích) dverí na vstavaných skriniach. Je možné ho využiť aj  do rámov na otváracie a výklopné dvierka kuchynských liniek, prípadne na dvierka obývačkového a kancelárskeho nábytku.",
  },
  {
    heading:
      "Kde si môžem objednať vstavanú skriňu s farebným sklom VARIOBEL®?",
    content:
      "Vstavanú skriňu so sklom VARIOBEL® si môžete objednať v neustále sa rozrastajúcej sieti predajní MOJASKRINA.SK",
  },
  {
    heading: "Aké farby si môžem vybrať?",
    content:
      "Farebné výplne VARIOBEL® si jednoducho vyberiete podľa farebného vzorkovníka TU (link bude neskôr), alebo na ktorejkoľvek našej prevádzke",
  },
  {
    heading: "Vyrobíte mi akúkoľvek farbu skla?",
    content:
      "Áno sklo vyrábame podľa presnej požiadavky stačí poslať kód farby CMYK, PANTONE alebo RAL",
  },
  {
    heading:
      "Ak som nenašiel požadovanú farbu vo vašej vzorkovnici viete mi aj tak pomôcť?",
    content:
      "V našej vzorkovnici nájdete skutočneveľké množstvo farieb a ich odtieňov, ak však máte špeciálnu požiadavku a nenašli ste nič podobné, tak vám vieme farbu „namiešať“ na základe HEX farebného kódu alebo podľa CMYK-u",
  },
];

export const FaqScreen = () => {
  return (
    <PageContainer sx={{ my: 2 }} maxWidth="md">
      <>
        <Stack
          sx={{ mb: 4, gap: 1, textAlign: "center" }}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            variant="h4"
            color="secondary"
            fontWeight={600}
            mb={1}
            sx={{
              fontSize: { xs: "1.65rem", md: "2.125rem" },
            }}
          >
            Vy sa pýtate, my odpovedáme
          </Typography>

          <Typography variant="body2" sx={{ fontSize: '0.85rem' }}>
            Určite ste zvedaví a hladní po informáciách o produkte.
          </Typography>
          <Typography variant="body2" sx={{ fontSize: '0.85rem' }}>
            Pripravili sme si odpovede na vaše otázky.
          </Typography>
        </Stack>

        <Grid container spacing={3}>
          {faqs.map((faq) => (
            <Grid item key={faq.heading} xs={12} md={6}>
              <FaqAccordion heading={faq.heading} content={faq.content} />
            </Grid>
          ))}
        </Grid>
      </>
    </PageContainer>
  );
};
