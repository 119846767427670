import React, { useState } from "react";
import { Box } from "@mui/system";
import { Autocomplete, TextField } from "@mui/material";
import { countries } from "../../../helpers/ecomm/country-list";
import { ICountry } from "../../../models/ecomm/customer";

interface ICountryDropdown {
  onChange: (newCountry: ICountry) => void;
  exclude?: string[];
  language?: string;
  variant?: "standard";
  countryValue: ICountry | null;
  error: boolean;
  disabled?: boolean;
}
export const CountryDropdown = ({
  onChange,
  exclude,
  language = "sk",
  variant = "standard",
  countryValue,
  error,
  disabled = true,
}: // errors = [],
ICountryDropdown) => {
  const [countryInputValue, setCountryInputValue] = useState<string>("");

  return (
    <Autocomplete
      value={countryValue}
      onChange={(event: any, newCountry) => {
        onChange(newCountry as ICountry);
      }}
      disabled={disabled}
      disablePortal
      id="combo-box-country"
      options={countries}
      getOptionLabel={(option: ICountry) => {
        return option.hasOwnProperty(`${language}_label`)
          ? //@ts-ignore
            option[`${language}_label`]
          : option.label;
      }}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.hasOwnProperty(`${language}_label`)
            ? //@ts-ignore
              option[`${language}_label`]
            : option.label}{" "}
          ({option.code}) +{option.phone}
        </Box>
      )}
      onInputChange={(event, newInputValue: string) => {
        setCountryInputValue(newInputValue);
      }}
      inputValue={countryInputValue}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          // error={errors.filter((e) => e.indexOf("Krajina") !== -1).length > 0}
          // helperText={
          //   errors.filter((e) => e.indexOf("Krajina") !== -1).length > 0
          //     ? errors.filter((e) => e.indexOf("Krajina") !== -1).join()
          //     : ""
          // }
          label="Krajina"
          inputProps={{
            ...params.inputProps,
            autoComplete: "country", // disable autocomplete and autofill
          }}
          variant={variant}
        />
      )}
    />
  );
};
