import { Typography, Box, Link } from "@mui/material";
import { Link as RouteLink } from "react-router-dom";

interface IAuthPageFooterProps {
  text: string;
  anchorText: string;
  anchorTo: string;
}

export const AuthPageFooter: React.FC<IAuthPageFooterProps> = ({
  text,
  anchorText,
  anchorTo,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        textAlign: "center",
        fontSize: 20,
      }}
    >
      <Typography
        sx={{
          fontWeight: 700,
        }}
      >
        {text}{" "}
        <Link component={RouteLink} to={anchorTo}>
          {anchorText}
        </Link>
      </Typography>
    </Box>
  );
};
