import * as React from "react";
import Draggable from "react-draggable";
import { Paper, PaperProps } from "@mui/material";

export const PaperDraggable = (props: PaperProps) => {
  const nodeRef = React.useRef<HTMLDivElement>(null);

  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'} nodeRef={nodeRef}>
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
};
