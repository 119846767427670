import { useEffect, useRef } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { FallbackProps } from "react-error-boundary";
import {
  Box,
  BoxProps,
  Button,
  Typography,
  styled,
} from "@mui/material";

import warningIcon from "../assets/images/warning-icon.svg";

const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "90vw",
    margin: "auto",
  },
}));

const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const errorLocation = useRef(location.pathname);

  // onPage change reset error and render
  useEffect(() => {
    if (location.pathname !== errorLocation.current) {
      resetErrorBoundary();
    }
  }, [location.pathname, resetErrorBoundary]);

  useEffect(() => {
    console.error("Error occured:", error);
  }, [error]);

  return (
    <Box className="content-center">
      <Box
        sx={{
          display: "grid",
          placeItems: "center",
          height: "100vh",
          textAlign: "center",
        }}
      >
        <Box component="div">
          <BoxWrapper sx={{ my: 3 }}>
            <Box
              component={"img"}
              sx={{
                width: { xs: "89.36px", sm: "120px", md: "200px" },
              }}
              src={warningIcon}
              alt="Warning Icon"
            />
          </BoxWrapper>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: 2,
              mb: 2,
            }}
          >
            <Typography
              component="p"
              fontWeight={900}
              color={"#F4A600"}
              fontSize={{ xs: "1.6875rem", sm: "2rem", md: "3rem" }}
            >
              Niekde nastala chyba.
            </Typography>

            <Typography component="p" color={"#383637"}>
              Stránku sa nám nepodarilo zobraziť. Prosím kontaktujte.{" "}
              <Box component="a" href="#" color={"#3A779E"}>
                podpora
              </Box>
            </Typography>
          </Box>

          <Box
            component="div"
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 2,
              }}
            >
              <Button
                onClick={() => navigate(-1)}
                variant="outlined"
                sx={(theme) => ({
                  px: 3,
                  py: 1,
                  color: theme.palette.secondary.main,
                  borderColor: theme.palette.secondary.main,
                  ":hover": { borderColor: "#3A779E" },
                })}
              >
                Späť
              </Button>

              <Button
                variant="contained"
                component={Link}
                to="/"
                sx={(theme) => ({
                  px: 3,
                  py: 1,
                  backgroundColor: theme.palette.secondary.main,
                  ":hover": { background: "#3A779E" },
                })}
              >
                Domov
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ErrorFallback;
