import { useNavigate } from "react-router-dom";
import { Icon, Typography, Box, IconButton } from "@mui/material";
import { useApp } from "../../../context/app-context";
import { useAuth } from "../../../hooks/useAuth";

interface IAddress {
  language: string;
  title: string;
  readOnly?: boolean;
}
export const OrderReviewAddress = (props: IAddress) => {
  const { language = "sk", title, readOnly } = props;

  const { customer } = useApp();
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleEditAddress = () => {
    navigate("/profile?tab=2", { state: { fromOrder: true } })
  }

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h6" gutterBottom sx={{ mt: 2, fontWeight: 700 }}>
          {title}
        </Typography>

        {!readOnly && user && (
          <IconButton size="small" sx={{ mt: 1.25 }} onClick={handleEditAddress}>
            <Icon color="secondary" fontSize="small" sx={{ fontSize: "1rem" }}>
              edit
            </Icon>
          </IconButton>
        )}
      </Box>

      {customer!.billingAddress[0].company && (
        <Typography sx={{ fontSize: ".95em" }} gutterBottom>
          {customer!.billingAddress[0].company}
        </Typography>
      )}

      <Typography sx={{ fontSize: ".85em" }}>
        {customer!.billingAddress[0].address}
      </Typography>

      <Typography sx={{ fontSize: ".85em" }}>
        {`${customer!.billingAddress[0].city}, ${
          customer!.billingAddress[0].postcode
        }`}
      </Typography>

      <Typography sx={{ fontSize: ".85em" }}>
        {customer!.billingAddress[0].country!.hasOwnProperty(
          `${language}_label`
        )
          ? //@ts-ignore
            customer.billingAddress[0].country![`${language}_label`]
          : customer.billingAddress[0].country!.label}
      </Typography>
    </>
  );
};
