import { useState, useEffect, Fragment } from "react";

import {
  Grid,
  Typography,
} from "@mui/material";
import { OrderReviewAddress } from "./order-review-address";
import { OrderItemsSummary } from "./order-items-summary";
import { IRowType } from "../../../models/ecomm/productTable";

interface IReview {
  readOnly?: boolean;
  rows?: IRowType[];
}

export default function OrderReview(props: IReview) {
  const { readOnly = false, rows: rowsProp } = props;

  const [language] = useState<string>("sk");

  // scroll to top on mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Typography variant="h6" gutterBottom>
        Súhrn objednávky
      </Typography>

      <OrderItemsSummary rowsProps={rowsProp} />

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={4}>
          <OrderReviewAddress
            title="Fakturačné údaje"
            language={language}
            readOnly={readOnly}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}
