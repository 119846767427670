import { Container, Toolbar, Typography, Box, Button } from "@mui/material";
import { Link } from "react-router-dom";

interface Props {
  navItems: {
    title: string;
    path: string;
  }[];
  inView: boolean;
}

const DesktopNav = (props: Props) => {
  const { inView, navItems } = props;

  return (
    <Container
      component="nav"
      maxWidth="xl"
      sx={{
        width: "100%",
        backgroundColor: "#ffffff",
        position: inView ? "relative" : "fixed",
        py: 1,
        ...(!inView && {
          top: 0,
          boxShadow: "0 1px 0 0 rgb(0 0 0 / 6%)",
        }),
      }}
    >
      <Toolbar
        sx={{
          justifyContent: "space-between",
        }}
      >
        <Typography component={Link} to="/">
          <Box component="img" sx={{ width: "200px" }} src="/logo.svg" />
        </Typography>

        <Box sx={{ display: "flex", gap: 2 }}>
          {navItems.map((item) => (
            <Button
              disableTouchRipple
              key={item.title}
              component={Link}
              to={item.path}
              sx={{
                color: "#383637",
                fontWeight: 600,
                fontSize: { xs: "default", sm: "18px" },
              }}
            >
              {item.title}
            </Button>
          ))}
        </Box>
      </Toolbar>
    </Container>
  );
};

export default DesktopNav;
