import React from "react";
import {
  Box,
} from "@mui/material";
import { IRowType } from "../../models/ecomm/productTable";
import { OrderItemsSummaryAlt } from "../ecomm/checkout/order-items-summary-alt";
import { IOrder } from "../../models/ecomm/order";

interface Props {
  order: IOrder;
}

const OrderContent = (props: Props) => {
  const { order } = props;
  const { lineItems, basketPrice } = order;

  const rows = lineItems.map((item) => {
    if (item.print.type === "color") {
      const value = JSON.parse(String(item.print.value));
      return {
        ...item,
        print: {
          ...item.print,
          value,
        },
      };
    }

    return item;
  }) as unknown as IRowType[];


  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
    >
      <OrderItemsSummaryAlt rowsProps={rows} basketProps={basketPrice} />
    </Box>
  );
};

export default OrderContent;
