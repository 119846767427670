import { styled } from "@mui/material";
import { StyledTabContentContainer } from "./styled-tab-content-container";

export const CustomColorTab = styled(StyledTabContentContainer)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),

  "& .w-color-colorful": {
    width: "100% !important",
  },

  "& .w-color-saturation": {
    borderRadius: "4px !important",
    borderBottom: "none !important",
    height: "102px !important",
    marginBottom: "16px",

    "& > div": {
      width: "16px !important",
      height: "16px !important",
      transform: "translate(-6px, -8px) !important",
    },
  },

  "& .w-color-hue": {
    height: "8px !important",

    "& > div": {
      borderRadius: "8px !important",
    },

    "& .w-color-interactive": {
      "& > div": {
        borderRadius: "50% !important",
        width: "16px !important",
        height: "16px !important",
        transform: "translate(0px, -4px) !important",
      },
    },
  },
}));
