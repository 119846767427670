import { Card, CardContent, Typography, CardMedia, Stack, useTheme, Icon } from "@mui/material";
import * as React from "react";
import parse from "html-react-parser";
import { Box } from "@mui/system";
import { IProduct } from "../../../models/ecomm/product";
import { useAuth } from "../../../hooks/useAuth";
import { useApp } from "../../../context/app-context";
import { calculateOnSaleDiscount } from "../../../helpers/ecomm/calculateDiscount";

interface IProductType {
  product: IProduct;
  onSelect?: (product: IProduct) => void;
}

export const Product = ({
  product,
  onSelect = (product: IProduct) => null,
}: IProductType) => {
  const image: string = product.images[0] || "/product-placeholder.png";
  const [hover, setHover] = React.useState(false);

  const { user } = useAuth();
  const { customer, basketPrice } = useApp();

  const [discountedPrice, setDiscountedPrice] = React.useState(0)

  const theme = useTheme();

  React.useEffect(() => {
    const discount = calculateOnSaleDiscount(product, customer, user);
    setDiscountedPrice(discount);
  }, [product, user, customer])

  const getPriceWithTax = (price: number) => {
    const taxAmount = (basketPrice.taxRate / 100) * price;
    const formatter = new Intl.NumberFormat("en", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    let priceWithDPH = price + taxAmount;
    priceWithDPH = Number(formatter.format(priceWithDPH));

    return priceWithDPH.toFixed(2);
  }

  return (
    <Card
      sx={{
        minWidth: 275,
        cursor: "pointer",
        position: "relative",
        overflow: "hidden",
      }}
      elevation={hover ? 8 : 2}
      onClick={() => {
        onSelect(product);
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {product.onSale && (
        <Box
          sx={{
            position: "absolute",
            background: theme.palette.company.main,
            color: "white",
            padding: '0.3rem 115px',
            // width: 300,
            transform: "rotateZ(-45deg)",
            transformOrigin: "150px 150px",
            left: 0,
            top: 0,
            zIndex: 11,
            display: "flex",
            aignItems: "center"
          }}
        >

         V zľave
         <Icon>money_off</Icon>
        </Box>
      )}

      <Box
        sx={{
          width: "100%",
          height: "250px",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <CardMedia
          component="img"
          height="250"
          sx={{
            position: "absolute",
            top: "0px",
            left: "0px",
            transform: hover ? "scale(0.95)" : "scale(0.85)",
            /*making the scaling smooth*/
            transition: "transform 0.5s ease",
          }}
          image={image}
          alt={product.name}
        />
      </Box>

      <CardContent>
        <Typography sx={{ fontSize: 16 }} color="text.primary" gutterBottom>
          {product.name}
        </Typography>

        {product.description && (
          <Typography variant="caption" color="text.secondary">
            {parse(product.description)}
          </Typography>
        )}

        <Typography variant="h5" component="div"></Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary"></Typography>

        <Stack
          direction="row"
          alignItems="baseline"
          justifyContent="center"
          // spacing={1.2}
        >
          <Typography variant="body2" mr={1}>
            od
          </Typography>

          <Typography variant="h6" sx={{ fontWeight: 700 }}>
            {!product.onSale && getPriceWithTax(product.price)}

            {product.onSale && (
              <>
                <Typography
                  component="span"
                  sx={{ fontSize: "0.85rem" }}
                  mr={0.25}
                >
                  <s>{getPriceWithTax(product.price)}</s>
                </Typography>
                {getPriceWithTax(discountedPrice)}
              </>
            )}
          </Typography>

          <Typography variant="body2">
            €/m<sup>2</sup>
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};
