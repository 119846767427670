import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";

import { Box, Card, Icon, Tab } from "@mui/material";
import axios from "axios";

import { useAuth } from "../hooks/useAuth";
import { useApp } from "../context/app-context";

import { PageContainer } from "../comps/pageContainer";
import OrderHistory from "../comps/profile/orderHistory";
import UserProfile from "../comps/profile/userProfile";
import DesktopTabs from "../comps/profile/desktopTabs";
import MobileTabs from "../comps/profile/mobileTabs";

import { IOrder } from "../models/ecomm/order";
import { ITab, OrderHistoryProps, UserProfileProps, LastOrderProps } from "../comps/profile/types";
import { UserDataType } from "../context/types";
import { sortByKey } from "../helpers/sortByKey";
import LastOrder from "../comps/profile/lastOrder";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const UserProfileComponent = (props: UserProfileProps) => <UserProfile {...props} />;
const OrderHistoryComponent = (props: OrderHistoryProps) => <OrderHistory {...props} />;
const LastOrderComponent = (props: LastOrderProps) => <LastOrder {...props} />;

const ProfileScreen = () => {
  const { user } = useAuth();
  const { customer } = useApp();

  const [tabValue, setTabValue] = useState(0);
  const [mobileTabOpen, setMobileTabOpen] = useState(false);

  const [orders, setOrders] = useState<IOrder[]>([]);
  const [isOrdersLoading, setIsOrdersLoading] = useState(true);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalSpent, setTotalSpent] = useState(0);
  const [lastOrder, setLastOrder] = useState<IOrder>();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const createQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(searchParams);
      params.set(name, value);

      return params.toString();
    },
    [searchParams]
  );

  const updateSearchParams = (value: string) => {
    const { pathname } = window.location;
    const url = pathname + "?" + createQueryString("tab", value);

    navigate(url);
  };

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    updateSearchParams(String(newValue));
  };

  const toggleMobileTab = () => setMobileTabOpen((prev) => !prev);
  const handleMobileTabChange = (newValue: number) => {
    setTabValue(newValue);
    toggleMobileTab();
  };

  // Get params from url on initial render
  useEffect(() => {
    const tab = searchParams.get("tab");

    if (tab) {
      setTabValue(Number(tab));
    }
  }, [searchParams]);

  // Get all orders
  useEffect(() => {
    if (customer) {
      const getOrders = async () => {
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_SERVER_HOST}/order/get-all/customer/${customer._id}`,
            { withCredentials: true }
          );

          setOrders(res.data.orders);
        } catch (error) {
          Promise.reject(error);
        }
      };

      (async () => {
        setIsOrdersLoading(true);
        await getOrders();
        setIsOrdersLoading(false);
      })();
    }
  }, [customer]);

  // Set total orders, total spent, latest order
  useEffect(() => {
    if (orders.length) {
      setTotalOrders(orders.length);

      const _totalSpent = orders.reduce((accumulator, { basketPrice }) => {
        const { price } = basketPrice;
        return accumulator + price;
      }, 0);

      setTotalSpent(Number(_totalSpent.toFixed(2)));

      const _order = [...orders].sort(sortByKey("createdAt", false))[0];
      setLastOrder(_order);
    }
  }, [orders]);

  // Check if routing is from order review address edit
  // If so, focus on input to edit said address
  useEffect(() => {
    const checkForOrderCallback = () => {
      const state = location.state as unknown as any;

      if (state && state.fromOrder) {
        // timeout to wait for DOM to have id
        setTimeout(() => {
          document.getElementById("profile-address")?.focus();
        }, 200)
      }
    }

    checkForOrderCallback()
  }, [location])

  const tabs: ITab[] = [
    {
      label: "Last Order",
      icon: "description_outline",
      selectedIcon: "description",
      component: () => LastOrderComponent({ order: lastOrder }),
    },
    {
      label: "Order History",
      icon: "access_time_outline",
      selectedIcon: "access_time_filled",
      component: () => OrderHistoryComponent({ orders, isOrdersLoading }),
    },
    {
      label: "User Profile",
      icon: "person_outline",
      selectedIcon: "person",
      component: () =>
        UserProfileComponent({
          user: user as UserDataType,
          customer,
          totalOrders,
          totalSpent,
        }),
    },
  ];

  if (!user) return null;

  return (
    <PageContainer>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "42px" }}>
        <Box sx={{ position: "relative" }}>
          <Card
            sx={(theme) => ({
              bgcolor: theme.palette.secondary.light,
              boxShadow: "none",
              borderRadius: "8px",
            })}
          >
            <DesktopTabs value={tabValue} onChange={handleTabChange}>
              {tabs.map(({ label, icon, selectedIcon }, index) => {
                const selected = tabs[tabValue].label === label;

                const getDisplayedIcon = () => {
                  if (selected) return selectedIcon;
                  return icon;
                };

                const displayedIcon = getDisplayedIcon();

                return (
                  <Tab
                    key={label}
                    label={label}
                    icon={<Icon>{displayedIcon}</Icon>}
                    iconPosition="start"
                    disableRipple
                  />
                );
              })}
            </DesktopTabs>

            <MobileTabs
              {...{
                tabs,
                tabValue,
                toggleMobileTab,
                mobileTabOpen,
                handleMobileTabChange,
              }}
            />
          </Card>
        </Box>

        {/* Tab Content */}
        {tabs.map(({ label, component }, index) => {
          if (tabValue !== index) return null;

          return (
            <div
              key={label}
              role="tabpanel"
              hidden={tabValue !== index}
              style={{ display: "flex", flexDirection: "column", gap: "42px" }}
            >
              {component && component()}
            </div>
          );
        })}
      </Box>
    </PageContainer>
  );
};

export default ProfileScreen;
