import React from "react";
import { TextField, Box, Typography, InputAdornment } from "@mui/material";

interface Props {
  label: string;
  name: string;
  value: number;
  error: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
export const TransformInputs = (props: Props) => {
  const { label, name, value, error, onChange } = props;

  return (
    <Box>
      <Typography variant="subtitle2" component="p" fontWeight={600}>
        {label}
      </Typography>

      <TextField
        value={value}
        onChange={onChange}
        size="small"
        name={name}
        type="number"
        error={error}
        InputProps={{
          endAdornment: <InputAdornment position="end">mm</InputAdornment>,
        }}
        inputProps={{ maxLength: 4 }}
        sx={{
          "& .MuiOutlinedInput-root": {
            paddingLeft: 0,
            paddingRight: "10px",
            width: 90,

            "&:hover": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#D0D5DD",
              },
            },

            "&.Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
                borderColor: "#D0D5DD",
              },
            },
          },

          "& .MuiOutlinedInput-input": {
            padding: "8.5px 5px 8.5px 10px",

            "&[type=number]": {
              "MozAppearance": "textfield",

              "&::-webkit-outer-spin-button, ::-webkit-inner-spin-button": {
                "WebkitAppearance": "none",
                margin: 0,
              },
            },
          },

          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#D0D5DD",
          },

          "& .MuiInputAdornment-root": {
            marginLeft: 0,
            "& > p": {
              fontWeight: 600,
              fontSize: "0.75rem",
              color: "#1C1B1F",
            },
          },
        }} />
    </Box>
  );
};
