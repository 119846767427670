import { styled } from "@mui/material";
import TabSelector from "../../tab-selector";

export const StyledTabSelector = styled(TabSelector)(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  minHeight: 0,
  marginBottom: theme.spacing(1),

  "& .MuiTab-root": {
    textTransform: "none",
    fontWeight: 600,
    fontSize: "0.85rem",
    padding: "8px 12px",
    minHeight: 0,

    "&.Mui-selected": {
      backgroundColor: "#D0D5DD",
    },
  },
})) as typeof TabSelector;
