import React from "react";
import {
  Box,
  Step,
  Button,
  StepButton,
  Stepper as MuiStepper,
  Breakpoint,
  CircularProgress,
} from "@mui/material";
import { PageContainer } from "./pageContainer";

interface IStepperProps {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  steps: Array<{
    label: string;
    component: React.ReactElement;
    onNext?: () => Promise<Boolean>;
    onBack?: () => Boolean;
  }>;
  maxWidth?: { stepper: Breakpoint; content: Breakpoint; footer: Breakpoint };
  onFinish: () => void;
  goBack?: React.ReactElement;
  isSubmitting: boolean;
}

export const Stepper = (props: IStepperProps) => {
  const {
    step,
    setStep,
    steps,
    maxWidth = { stepper: "md", content: "md", footer: "md" },
    onFinish,
    goBack,
    isSubmitting,
  } = props;

  return (
    <Box sx={{ gap: 5, display: "flex", flexDirection: "column" }}>
      <PageContainer sx={{ my: 0, mt: 2 }} maxWidth={maxWidth.stepper}>
        <MuiStepper activeStep={step}>
          {steps.map(({ label }, index) => (
            <Step key={label} completed={index < step}>
              <StepButton color="inherit" onClick={() => setStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </MuiStepper>
      </PageContainer>

      <PageContainer sx={{ my: 0 }} maxWidth={maxWidth.content}>
        {steps[step].component}
      </PageContainer>

      <PageContainer sx={{ my: 0 }} maxWidth={maxWidth.footer}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          {step === 0 && goBack}

          {step > 0 && (
            <Button
              color="inherit"
              disabled={step === 0}
              onClick={() => setStep(step - 1)}
              sx={{ mr: 1 }}
            >
              Späť
            </Button>
          )}

          <Box sx={{ flex: "1 1 auto" }} />

          {step < steps.length - 1 && (
            <Button
              variant="contained"
              color="company"
              sx={{ color: "white" }}
              onClick={async () => {
                const onNext = await steps[step].onNext?.();
                if (onNext) setStep(step + 1);
              }}
            >
              Ďalej
            </Button>
          )}

          {step === steps.length - 1 && (
            <Button
              variant="contained"
              color="success"
              startIcon={
                isSubmitting ? (
                  <CircularProgress
                    style={{ color: "white", width: 22, height: 22 }}
                  />
                ) : null
              }
              disabled={isSubmitting}
              onClick={() => onFinish()}
            >
              Odoslať objednávku
            </Button>
          )}
        </Box>
      </PageContainer>
    </Box>
  );
};
