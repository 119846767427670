import { Box, Stack, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import OrderReview from "../../comps/ecomm/checkout/order-review";
// import { IBasketPrice } from "../../models/ecomm/checkout";
// import { ICustomer } from "../../models/ecomm/customer";
import { IOrderResponse } from "../../models/ecomm/order";
import { IRowType } from "../../models/ecomm/productTable";
// import { IRowType } from "../../models/ecomm/product-mui-table";

import { EcommScreen } from "./ecomm";
import { PageContainer } from "../../comps/pageContainer";

interface IState {
  order: IOrderResponse;
  rowsProp: IRowType[];
}

export const SuccessScreen = () => {
  const location = useLocation();
  if (location.state == null) return <EcommScreen />;

  const { order, rowsProp } = location.state as IState;
  return (
    <PageContainer maxWidth="md">
      <>
        <Stack
          direction="column"
          alignItems="center"
          spacing={2}
          sx={{ mb: 3, textAlign: "center" }}
        >
          <Typography
            variant="h4"
            color="secondary"
            fontWeight={600}
            sx={{
              fontSize: { xs: "1.65rem", md: "2.125rem" },
            }}
          >
            Ďakujeme za Vašu objednávku.
          </Typography>

          <Typography
            variant="body1"
            sx={{ fontSize: { xs: "0.85rem", sm: "initial" } }}
            gutterBottom
          >
            Objednávka
            <Box component="span" sx={{ fontWeight: 700 }}>
              {` #${order.orderNumber} `}
            </Box>
            bola úspešne odoslaná. O jej stave Vás budeme informovať emailom.
          </Typography>
        </Stack>

        <OrderReview rows={rowsProp} readOnly />
      </>
    </PageContainer>
  );
};
