import { Link, useNavigate } from "react-router-dom";
import { Box, BoxProps, Button, styled } from "@mui/material";
import Lottie from "lottie-react";

import notFound from "../assets/images/not-found.json";
import notFoundMobile from "../assets/images/not-found-mobile.json";

const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "100vw",
  },
}));

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Box className="content-center">
      <Box
        sx={{
          display: "grid",
          placeItems: "center",
          height: "100vh",
          textAlign: "center",
        }}
      >
        <Box component="div">
          <BoxWrapper sx={{ mt: 5, mb: 5 }}>
            <Box component="div" display={{ sm: "none" }}>
              <Lottie
                style={{ width: "300px", margin: "auto" }}
                animationData={notFoundMobile}
                loop={false}
              />
            </Box>
            <Box component="div" display={{ xs: "none", sm: "block" }}>
              <Lottie
                style={{ maxWidth: "500px", margin: "auto" }}
                animationData={notFound}
                loop={false}
              />
            </Box>
          </BoxWrapper>

          <Box display={"flex"} justifyContent={"center"} gap={2}>
            <Button
              variant="outlined"
              onClick={() => navigate(-1)}
              sx={(theme) => ({
                px: 3,
                py: 1,
                color: theme.palette.secondary.main,
                borderColor: theme.palette.secondary.main,
                ":hover": { borderColor: "#3A779E" },
              })}
            >
              Späť
            </Button>

            <Button
              component={Link}
              to="/"
              variant="contained"
              sx={(theme) => ({
                px: 3,
                py: 1,
                backgroundColor: theme.palette.secondary.main,
                ":hover": { background: "#3A779E" },
              })}
            >
              Domov
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NotFound;
