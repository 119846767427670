import { useState } from "react";

import { FormikHelpers } from "formik";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import axios from "axios";

import { AuthPageFooter } from "../comps/login/authPageFooter";
import { AuthPageWrapper } from "../comps/login/authPageWrapper";
import { PageContainer } from "../comps/pageContainer";
import { SignUpForm } from "../comps/signup/signUpForm";

import { useAuth } from "../hooks/useAuth";
import { ICustomerForm } from "../models/ecomm/customer";
import { useSnackbar } from "notistack";

export const sendCustomerEmaiVerification = async (to: string) => {
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_SERVER_HOST}/mail/sendVerification`,
    data: { to },
  };

  return await axios(config)
    .then((res) => res)
    .catch((error) => error);
};

const VerificationBox = ({ to }: { to: string }) => {
  const [countdown, setCountDown] = useState(30);
  const [sending, setSending] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  if (countdown > 0) {
    let val = countdown - 1;
    setTimeout(() => setCountDown(val), 1000);
  }

  const sendVerify = async () => {
    setSending(true);
    await sendCustomerEmaiVerification(to)
      .then((res) => {
        if (res.status === 200) {
          enqueueSnackbar("Email bol odoslaný: Prosím skontrolujte doručenú poštu", {
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar("Niekde nastala chyba, prosím kontaktujte podporu", {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      })
      .finally(() => setSending(false));
  };

  return (
    <AuthPageWrapper header="Verification">
      <Typography textAlign="center">
        Overovací odkaz nájdete vo svojom e-maile
      </Typography>

      <Box sx={{ display: "grid", placeItems: "center", mt: 1 }}>
        <Typography color="secondary" fontWeight={600}>
          00:{String(countdown).padStart(2, "0")}
        </Typography>

        <Button
          variant="contained"
          disabled={countdown > 0}
          onClick={sendVerify}
          sx={{
            textTransform: "capitalize",
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          {sending && <CircularProgress color="secondary" size={22} />}
          Znova odoslať overenie
        </Button>
      </Box>
    </AuthPageWrapper>
  );
};

export const SignupScreen = (): JSX.Element => {
  const { register } = useAuth();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [to, setTo] = useState("");

  const handleSubmit = async (
    values: ICustomerForm,
    helpers: FormikHelpers<ICustomerForm>
  ) => {
    // field is only used for UI
    // @ts-ignore
    delete values.isCompany;

    const sendVerify = () => sendCustomerEmaiVerification(values.email);
    const { setSubmitting, resetForm } = helpers;
    setError("");

    await register(
      values,
      () => {
        setSuccess(true);
        sendVerify();
        resetForm();
        setTo(values.email);
      },
      (err) => {
        const { error } = err.response?.data as unknown as any;

        if (error) {
          setError(error);
        }
      }
    );

    setSubmitting(false);
  };

  if (success) {
    return (
      <PageContainer>
        <VerificationBox {...{ to }} />
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <AuthPageWrapper header="Registrácia nového zákazníka" type="reg">
        <SignUpForm {...{ handleSubmit }} />

        {error && (
          <Alert severity="warning" sx={{ alignItems: "center", mb: 2 }}>
            {error}
          </Alert>
        )}

        <AuthPageFooter
          text="Už ste sa zaregistrovali?"
          anchorText="Prihlásiť"
          anchorTo="/prihlasenie"
        />
      </AuthPageWrapper>
    </PageContainer>
  );
};
