import { Box, Typography, Icon, ClickAwayListener } from "@mui/material";
import { ITab, IUserProfileDetailsTab } from "./types";
import { ICustomer } from "../../models/ecomm/customer";

interface Props {
  tabs: (ITab | IUserProfileDetailsTab) [],
  tabValue: number;
  toggleMobileTab: () => void;
  mobileTabOpen: boolean;
  handleMobileTabChange: (newValue: number) => void;

  // the below only used in IUserProfileDetailsTab
  customer?: ICustomer;
  editableValues?: any;
}

const MobileTabs = (props: Props) => {
  const { tabs, tabValue, toggleMobileTab, mobileTabOpen, handleMobileTabChange, customer, editableValues } = props;

  const handleClickAway = () => {
    if (!mobileTabOpen) return;

    toggleMobileTab();
  };

  const renderTab = (tab: ITab | IUserProfileDetailsTab, selected: boolean) => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          height: "60px",
          color: selected ? "inherit" : "rgba(0, 0, 0, 0.6)",
        }}
      >
        {"icon" in tab && <Icon>{selected ? tab.selectedIcon : tab.icon}</Icon>}
        <Typography color="inherit">{tab.label}</Typography>
      </Box>
    );
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={{ paddingLeft: "30px", width: "100%", display: { sm: "none" } }}>
        <Box
          sx={{
            height: "60px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {renderTab(tabs[tabValue], true)}

          <Box
            onClick={toggleMobileTab}
            sx={{
              background: "#C6D2D8",
              height: "100%",
              width: "90px",
              display: "grid",
              placeItems: "center",
            }}
          >
            <Icon sx={{ fontSize: "1.65rem" }}>
              {mobileTabOpen ? "keyboard_arrow_up" : "keyboard_arrow_down"}
            </Icon>
          </Box>
        </Box>

        <Box
          sx={{
            overflow: "hidden",
            height: mobileTabOpen ? 120 : 0,
            transition: "height 150ms linear",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          {tabs.map((tab, index) => {
            const isCo = "isCompany" in tab;

            const isCompanyTab = isCo ? (tab.isCompany ?? false) : false;
            const hasCompanyAccount = customer?.company.name;
            const formCheckboxCompanyAccount = editableValues?.hasCompanyData

            const disableTab = isCompanyTab && !hasCompanyAccount && !formCheckboxCompanyAccount;

            if (tabValue === index) return null;

            return (
              <Box
                key={tab.label}
                onClick={() => handleMobileTabChange(index)}
                sx={{
                  width: "100%",
                  opacity: disableTab ? 0.5 : 1,
                  pointerEvents: disableTab ? "none" : "auto",
                }}
              >
                {renderTab(tab, false)}
              </Box>
            );
          })}
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

export default MobileTabs;
