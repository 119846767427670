import * as Yup from "yup";
import { AddressSchema } from "../comps/signup/signUpForm";

export const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("Meno je povinný údaj")
    .max(40, "Meno ma príliž veľa znakov"),
  lastName: Yup.string()
    .required("Priezvisko je povinný úda")
    .max(40, "Priezvisko ma príliž veľa znakov"),
  email: Yup.string()
    .required("Email  je povinný údaj")
    .email("Zadaný email nie je platná emailová adresa"),
  password: Yup.string(),
  phone: Yup.string(),

  billingAddress: Yup.array().of(AddressSchema),

  // optional here as its only used in edit mode
  gdprConsent: Yup.boolean(),
  marketingConsent: Yup.boolean(),
});

export interface schemaType extends Yup.InferType<typeof validationSchema> {}
