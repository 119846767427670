import React from "react";
import { Box, Paper } from "@mui/material";
import { AuthPageHeader } from "./authPageHeader";

interface IAuthPageWrapperProps {
  header: string;
  type?: string;
  children: React.ReactNode;
}

export const AuthPageWrapper: React.FC<IAuthPageWrapperProps> = ({
  header,
  type,
  children,
}) => {
  return (
    <Box
      sx={{
        width: "fit-content",
        height: "fit-content",
        maxWidth: 800,
        margin: "0 auto",
        padding: 5,
        backgroundColor: "#fff",
        borderRadius: 2,
        boxShadow:
          "0px 0px 1.9px rgba(0, 0, 0, 0.007),0px 0px 4.9px rgba(0, 0, 0, 0.014),0px 0px 9.9px rgba(0, 0, 0, 0.021),0px 0px 20.4px rgba(0, 0, 0, 0.031),0px 0px 56px rgba(0, 0, 0, 0.05)",
      }}
      component={Paper}
    >
      <AuthPageHeader header={header} />
      <Box
        width={{ xs: "100%", md: type === "reg" ? 500 : 350 }}
        sx={{
          margin: "0 auto",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
