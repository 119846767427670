import { useEffect, forwardRef, ReactNode } from "react";

import Typography from "@mui/material/Typography";
// import { IWooCustomer } from "../../../models/ecomm/woocommerce";
import { OrderAddressForm, FormikRef } from "./order-address-form";
import { Alert, Box, Grid } from "@mui/material";
// import ShippingZoneMethod from "./shipping-zone-method";
import { OrderItemsSummary } from "./order-items-summary";
// import { useApp } from "../../../context/app-context";

interface Props {
  children?: ReactNode;
}

const ShippingDetails = forwardRef<FormikRef, Props>((props, ref) => {
  // const { customer } = useApp();

  // scroll to top on mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Grid container>
            <Grid item xs={12} sx={{ mb: 4 }}>
              <Typography variant="h6" gutterBottom>
                Fakturačné údaje
              </Typography>

              <OrderAddressForm ref={ref} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box border="1px solid #0000001f" borderRadius={1} sx={{ px: 1.5 }}>
            <OrderItemsSummary options={{ summaryTextVariant: "caption" }} />
          </Box>

          <Box sx={{ mt: 1.5 }}>
            <Alert severity="info" sx={{ alignItems: "center" }}>
              Momentálne objednávky vybavujeme len na osobný odber.
            </Alert>
          </Box>
        </Grid>
      </Grid>
    </>
  );
})

export default ShippingDetails;
