import { Box, Card, CircularProgress, Typography } from "@mui/material";
import OrderContent from "./orderContent";
import { LastOrderProps } from "./types";

const LastOrder = (props: LastOrderProps) => {
  const { order } = props;

  if (!order) {
    return (
      <Box
        sx={{
          display: "grid",
          placeItems: "center",
          height: "400px"
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  };

  return (
    <Card
      sx={(theme) => ({
        bgcolor: theme.palette.secondary.light,
        maxWidth: "sm",
        width: "100%",
        margin: "auto",
        boxShadow: "none",
        borderRadius: "8px",
        p: { xs: "36px", },

        "& .MuiAccordionSummary-root, .MuiAccordionDetails-root": {
          bgcolor: theme.palette.secondary.light,
        }
      })}
    >
      <Typography
        mb={2}
        color="secondary"
        sx={{ fontSize: { xs: "1rem", md: "1.15rem" } }}
      >
        Súhrn objednávky #{order.orderNumber}
      </Typography>
      <OrderContent {...{ order }} />
    </Card>
  );
};

export default LastOrder;
