import React, { useState } from "react";

import { Card, Icon, IconButton, Typography, Box } from "@mui/material";
import {
  GridActionsCellItem,
  GridAlignment,
} from "@mui/x-data-grid";

import { format } from "date-fns";

import { IOrder } from "../../models/ecomm/order";
import { OrderHistoryProps } from "./types";

import { OrderSummaryDialog } from "./orderSummaryDialog";
import { OrderInvoiceDialog } from "./orderInvoiceDialog";

import DataGrid from "../dataGrid";
import { CustomPagination } from "./customPagination";
import DownloadDataButton from "./downloadDataButton";

interface CellType {
  row: IOrder;
}

const OrderHistory = (props: OrderHistoryProps) => {
  const { orders, isOrdersLoading } = props;

  const rowsPerPageOptions = [10, 25, 50];
  const [pageSize, setPageSize] = useState<number>(10);

  const [orderSummaryOpen, setOrderSummaryOpen] = useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] = useState<IOrder>();

  const [orderInvoiceOpen, setOrderInvoiceOpen] = useState<boolean>(false);

  const toggleOrderSummaryDialog = () => setOrderSummaryOpen((prev) => !prev);
  const toggleOrderInvoiceDialog = () => setOrderInvoiceOpen((prev) => !prev);

  const goToPreviousPageOption = () => {
    const currentIndex = rowsPerPageOptions.indexOf(pageSize);
    const prevIndex = currentIndex > 0 ? currentIndex - 1 : rowsPerPageOptions.length - 1;

    setPageSize(rowsPerPageOptions[prevIndex]);
  };

  const goToNextPageOption = () => {
    const currentIndex = rowsPerPageOptions.indexOf(pageSize);
    const nextIndex = (currentIndex + 1) % rowsPerPageOptions.length;

    setPageSize(rowsPerPageOptions[nextIndex]);
  };

  const columns = [
    {
      flex: 0.15,
      minWidth: 150,
      field: "order",
      headerName: "Order",
      headerAlign: "left" as GridAlignment,
      align: "left" as GridAlignment,
      renderCell: ({ row }: CellType) => (
        <Typography
          variant="body2"
          onClick={() => {
            setSelectedOrder(row);
            toggleOrderSummaryDialog();
          }}
          sx={{
            "&:hover": {
              cursor: "pointer",
              textDecoration: "underline",
            },
          }}
        >
          #{row.orderNumber}
        </Typography>
      ),
    },

    {
      flex: 0.2,
      minWidth: 150,
      field: "createdAt",
      headerName: "Date Created",
      headerAlign: "left" as GridAlignment,
      align: "left" as GridAlignment,
      renderCell: ({ row }: CellType) => (
        <Typography variant="body2">
          {format(new Date(row.createdAt), "dd MMM yyyy, h:mm a")}
        </Typography>
      ),
    },

    {
      flex: 0.2,
      minWidth: 240,
      field: "status",
      headerName: "Order Status",
      headerAlign: "left" as GridAlignment,
      align: "left" as GridAlignment,
      renderCell: ({ row }: CellType) => (
        <Typography variant="body2">{row.orderStatus.name}</Typography>
      ),
    },

    {
      flex: 0.2,
      minWidth: 150,
      field: "price",
      headerName: "Price",
      headerAlign: "left" as GridAlignment,
      align: "left" as GridAlignment,
      renderCell: ({ row }: CellType) => (
        <Typography variant="body2">
          {row.basketPrice.price.toFixed(2)}
          <Typography component="span" color="secondary" variant="inherit">
            {" "}
            €
          </Typography>
        </Typography>
      ),
    },

    {
      flex: 0.2,
      minWidth: 100,
      sortable: false,
      field: "actions",
      headerName: "Actions",
      type: "actions",
      getActions: ({ row }: CellType) => [
        <GridActionsCellItem
          showInMenu
          label="Zobraziť objednávku"
          onClick={() => {
            setSelectedOrder(row);
            toggleOrderSummaryDialog();
          }}
        />,
        <GridActionsCellItem
          showInMenu
          label="Zobraziť faktúru"
          onClick={() => {
            setSelectedOrder(row);
            toggleOrderInvoiceDialog();
          }}
        />,
      ],
    },
  ];

  return (
    <>
      <Card
        sx={(theme) => ({
          bgcolor: theme.palette.secondary.light,
          boxShadow: "none",
          borderRadius: "8px",
          height: "74px",

          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 20px",
        })}
      >
        <Typography
          variant="body2"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1.25,
          }}
        >
          Show
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              background: "#C6D2D8",
              p: "8px 10px",
              gap: "10px",
              borderRadius: "5px",
              fontWeight: 600,
            }}
          >
            {pageSize}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                "& .MuiIconButton-root": {
                  p: 0,
                  flexDirection: "column",

                  "& .MuiIcon-root": {
                    fontSize: "1rem",
                    fontWeight: 600,
                    color: "#1C1B1F",
                  },
                },
              }}
            >
              <IconButton onClick={goToPreviousPageOption}>
                <Icon>expand_less</Icon>
              </IconButton>
              <IconButton onClick={goToNextPageOption}>
                <Icon>expand_more</Icon>
              </IconButton>
            </Box>
          </Box>
          Entries
        </Typography>

        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <DownloadDataButton />
        </Box>
      </Card>

      <DataGrid
        autoHeight
        initialState={{
          sorting: {
            sortModel: [{ field: "createdAt", sort: "desc" }],
          },
        }}
        rows={orders}
        getRowId={(row) => row._id}
        columns={columns}
        disableSelectionOnClick
        loading={isOrdersLoading}
        pagination
        rowsPerPageOptions={rowsPerPageOptions}
        pageSize={pageSize}
        components={{
          Pagination: CustomPagination,
        }}
        sx={(theme) => ({
          "& .MuiDataGrid-footerContainer": {
            minHeight: 74,
            backgroundColor: "white",
            borderTop: "none",
            justifyContent: { xs: "center", md: "space-between" },
          },

          "& .MuiDataGrid-row--lastVisible": {
            borderRadius: "0 0 8px 8px",
          },

          "& .MuiDataGrid-actionsCell": {
            "& .MuiIconButton-root > svg": {
              fill: "#101828",
            },
          },
        })}
      />

      {selectedOrder && (
        <>
          <OrderSummaryDialog
            open={orderSummaryOpen}
            handleClose={toggleOrderSummaryDialog}
            order={selectedOrder}
          />

          <OrderInvoiceDialog
            open={orderInvoiceOpen}
            handleClose={toggleOrderInvoiceDialog}
            order={selectedOrder}
          />
        </>
      )}
    </>
  );
};

export default OrderHistory;
