import { Box, Grid, Typography } from "@mui/material";
import { PageContainer } from "../comps/pageContainer";
import banner from "../assets/images/home-banner-bkg-hd1.svg";
import { ThemeButton } from "../comps/theme-button";

export const HomeScreen = () => {
  function importAll(r: any) {
    return r.keys().map((item: string) => r(item));
  }

  const thumbs: any[] = importAll(
    require.context("../assets/images/wallpaper_grid", false, /\.(svg)$/)
  );
  const section2Data = [
    {
      text: "Výplne kuchynských dvierok",
      image: thumbs[0],
    },
    {
      text: "Kuchynské zásteny",
      image: thumbs[1],
    },
    // {
    //   text: "Výplne kuchynských dvierok",
    //   image: thumbs[2],
    // },
    // {
    //   text: "Čelá zásuviek",
    //   image: thumbs[3],
    // },
    {
      text: "Výplne posuvných dverí",
      image: thumbs[3],
    },
    {
      text: "Výplne otváracích dvierok",
      image: thumbs[5],
    },
  ];
  return (
    <PageContainer>
      <Box
        sx={{
          minHeight: "100vh",
        }}
      >
        {/* section 1 */}
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "row" },
            overflow: "hidden",
            mb: 4,
          }}
        >
          {/* Images */}
          <>
            <Box sx={{ display: { xs: "none", md: "block", width: "100%" } }}>
              <img
                src={banner}
                alt="banner"
                style={{
                  width: "100%",
                }}
              />
            </Box>

            {/* mobile */}
            <Box
              sx={{
                display: {
                  xs: "block",
                  md: "none",
                  width: "160%",
                  overflow: "hidden",
                },
              }}
            >
              <img
                src={banner}
                alt="banner"
                style={{
                  width: "100%",
                  margin: "0 -40%",
                }}
              />
            </Box>
          </>

          {/* Text */}
          <Box
            sx={{
              position: { xs: "relative", md: "absolute" },
              top: { xs: "0", md: "50%" },
              transform: { xs: "translateY(0%)", md: "translateY(-50%)" },
            }}
          >
            <Box
              ml={{ xs: 0, md: 4 }}
              mb={{ xs: 4, md: 0 }}
              sx={{
                width: { xs: "90%", md: "35%" },

                display: "flex",
                flexDirection: { md: "column", xs: "column" },
                alignItems: "flex-start",
                gap: 2,
              }}
            >
              <Typography
                variant="h4"
                color="secondary"
                fontWeight="800"
                sx={{
                  fontSize: { xs: "1.65rem", md: "2.125rem" },
                }}
              >
                Sklo s nekonečnými farebnými možnosťami
              </Typography>

              <Typography>
                Inšpirujte sa našimi farebnými paletami, alebo si sami
                namiešajte svoje obľúbené farby, Je to jednoduché, zvyšok už
                nechajte na nás.
              </Typography>

              <ThemeButton to="/obchod" id="second_demo_step">
                Vyber si sklo
              </ThemeButton>
            </Box>
          </Box>
        </Box>

        {/* section 2 */}
        <Box my={4}>
          <Typography
            variant="h5"
            // color="#005486"
            sx={{
              fontSize: { xs: "1.35rem", md: "1.5rem" },
              textAlign: "center",
              fontWeight: 800,
              my: 5,
            }}
          >
            Využitie skla VARIOBEL a VARIOBORD
          </Typography>

          <Grid container justifyContent="center">
            {section2Data.map((item, i) => (
              <Grid
                item
                sx={{
                  xs: 12,
                  md: 2,
                  display: "flex",
                  gap: 1,
                  flexDirection: { xs: "row", md: "column" },
                }}
                key={i}
              >
                <img
                  src={item.image}
                  alt="slide"
                  style={{ width: "100%", height: "200px" }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    width: "90%",
                    margin: { xs: "auto", md: "0 auto" },
                    textAlign: "center",
                    fontWeight: 800,
                  }}
                >
                  {item.text}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </PageContainer>
  );
};
