import { FormikHelpers } from "formik";
import { useState } from "react"

import { AuthPageFooter } from "../comps/login/authPageFooter";
import { AuthPageWrapper } from "../comps/login/authPageWrapper";
import { LoginForm } from "../comps/login/loginForm";
import { PageContainer } from "../comps/pageContainer";

import { useAuth } from "../hooks/useAuth";
import { Alert, Box, Button, Typography } from "@mui/material";
import { ResetPasswordForm, resetPasswordFormSchemaType } from "../comps/login/resetPassword";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import axios from "axios";

export const LoginScreen = (): JSX.Element => {
  const [error, setError] = useState("");
  const [forgotPasswordMode, setForgotPasswordMode] = useState(false);

  const { login } = useAuth();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleLoginSubmit = async (
    { email, password }: { email: string; password: string },
    { setSubmitting }: FormikHelpers<{ email: string; password: string }>
  ) => {
    await login({ email, password }, (err) => {
      const { error } = err.response?.data as unknown as any;

      if (error) {
        setError(error);
      }
    });

    setSubmitting(false);
  };

  const handleResetSubmit = async (
    values: resetPasswordFormSchemaType,
    formikHelpers: FormikHelpers<resetPasswordFormSchemaType>
  ) => {
    const { email } = values;
    const { setSubmitting } = formikHelpers;

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_SERVER_HOST}/mail/sendPasswordReset`,
      data: { email },
    };

    try {
      await axios(config);
      enqueueSnackbar("Žiadosť bola odoslaná", { variant: "success" });
      navigate("/");
    } catch (error) {
      const axiosError = (error as any).response;

      if (!axiosError.data) {
        return enqueueSnackbar("Niekde nastala chyba", { variant: "warning" });
      }

      if (axiosError.data.error) {
        enqueueSnackbar(axiosError.data.error as string, { variant: "warning" });
      } else {
        Promise.reject(error);
      }
    }

    setSubmitting(false);
  };

  return (
    <PageContainer>
      <AuthPageWrapper
        header={forgotPasswordMode ? "Obnoviť heslod" : "Prihlásiť sa"}
      >
        {!forgotPasswordMode && <LoginForm handleSubmit={handleLoginSubmit} />}

        {forgotPasswordMode && (
          <ResetPasswordForm handleSubmit={handleResetSubmit} />
        )}

        {error && (
          <Alert severity="warning" sx={{ alignItems: "center", mb: 2 }}>
            {error}
          </Alert>
        )}

        {!forgotPasswordMode && (
          <>
            <AuthPageFooter
              text="Ešte nie ste zaregistrovaný?"
              anchorText="Registruj sa tu"
              anchorTo="/registracia"
            />

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                sx={{ fontWeight: 700, mt: 0.5, textTransform: "none" }}
                component={Button}
                onClick={() => setForgotPasswordMode(true)}
              >
                Zabudol si heslo?
              </Typography>
            </Box>
          </>
        )}
      </AuthPageWrapper>
    </PageContainer>
  );
};
