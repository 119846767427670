import React, { SyntheticEvent, useRef, useState } from "react";

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Tab,
  useTheme,
  useMediaQuery,
  Paper,
  LinearProgress,
  IconButton,
  Icon,
} from "@mui/material";

import { TabContext, TabPanel } from "@mui/lab";

import ColorPickerTab from "./tabs/color-picker-tab";
import WallpaperTab from "./tabs/wallpaper-tab";
import CustomWallpaperTab from "./tabs/custom-wallpaper-tab";
import { getCroppedImg } from "./tabs/custom-wallpaper-tab/helpers";

import {
  IFile,
  PrintTabSelectorType,
  VariobelColor,
} from "../../../../models/ecomm/productTable";

import axios from "axios";
import { useSnackbar } from "notistack";
import { Area /* ,Point */ } from "react-easy-crop";
import { useApp } from "../../../../context/app-context";
import { TabSubmitButton } from "./tab-submit-button";
import {
  getColorFromProp,
  getCustomWallpaperFromProp,
  getLocalWallpaperFromProp,
  getLocalWallpapers,
} from "./helpers";
import TabSelector from "./tabs/tab-selector";
import { useDispatch, useSelector } from "react-redux";
import { RootState, updateCustomWallpaperState, updateLocalWallpaperState } from "./redux";

interface Props {
  open: boolean;
  selectedValue: string | VariobelColor | IFile;
  selectedTab: PrintTabSelectorType;
  selectedID: number | string;
  handleSelect: (value: string | VariobelColor | IFile, tab: string) => void;
  closeDialog: () => void;
}

interface ITabs {
  label: string;
  value: PrintTabSelectorType;
}

const tabs: ITabs[] = [
  {
    label: "Farba",
    value: "color",
  },
  {
    label: "Tapeta",
    value: "wallpaper",
  },
  {
    label: "Vlastná tapeta",
    value: "ownWallpaper",
  },
];

const PrintDialog = (props: Props) => {
  const { open, handleSelect, closeDialog, selectedValue, selectedTab, selectedID } = props;

  const dispatch = useDispatch();
  const { customWallpaperState, localWallpaperState } = useSelector((state: RootState) => state);

  const initialColor = getColorFromProp(selectedTab, selectedValue);

  const { rows, updateAppContext } = useApp();

  const [tab, setTab] = useState(selectedTab);
  const [color, setColor] = useState<VariobelColor>(initialColor);

  const imgRef = useRef<HTMLImageElement | any>();
  const previewCanvasRef = useRef<HTMLCanvasElement | any>();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    const localWallpapers = getLocalWallpapers();

    const initialLocalWallpaper = getLocalWallpaperFromProp(
      selectedTab,
      selectedValue,
      localWallpapers
    );

    dispatch(
      updateLocalWallpaperState({
        type: "manual",
        localWallpapers,
        wallpaper: initialLocalWallpaper,
      })
    );
  }, [dispatch, selectedTab, selectedValue]);

  React.useEffect(() => {
    const initialCustomWallpaper = getCustomWallpaperFromProp(
      selectedTab,
      selectedValue
    );

    dispatch(
      updateCustomWallpaperState({
        type: "custom",
        wallpaper: initialCustomWallpaper,
        isCropUploaded: false,
        uploadingWallpaper: false,
        cropWallpaperImgData: undefined,
      })
    );
  }, [dispatch, selectedTab, selectedValue]);

  const selectCroppedImage = async () => {
    try {
      let state: any = customWallpaperState;

      if (tab === "wallpaper") {
        state = localWallpaperState
      } else {
        state = customWallpaperState
      };

      const _croppedImage = await getCroppedImg(
        state.imgSrc,
        state.croppedAreaPixels as Area,
        state.rotation
      );

      if (_croppedImage) {
        if (tab === "wallpaper") {
          dispatch(updateLocalWallpaperState({
            croppedImage: _croppedImage.objectURL as string,
            isCropMode: false
          }))
        }

        if (tab === "ownWallpaper") {
          dispatch(updateCustomWallpaperState({
            cropWallpaperImgData: _croppedImage.blob,
            croppedImage: _croppedImage.objectURL as string,
            isCropMode: false,
          }))

        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Reset order quantity on tab change
  const resetOrderItemQuantity = () => {
    const updatedRows = rows.map((row) => {
      if (row.id === selectedID) {
        return {
          ...row,
          qty: 1
        }
      }

      return row;
    });

    updateAppContext({
      rows: updatedRows,
    });
  };

  // handle tab change
  const handleTabChange = (_: SyntheticEvent, newValue: string) => {
    setTab(newValue as PrintTabSelectorType);
    resetOrderItemQuantity();
  };

  // handle print select and close dialog
  const handlePrintSelect = () => {
    const printValue = () => {
      if (tab === "color") {
        return color as VariobelColor;
      }

      if (tab === "wallpaper") {
        return localWallpaperState.wallpaper as IFile;
      }

      // tab === ownWallpaper
      return customWallpaperState.wallpaper as IFile;
    };

    handleSelect(printValue(), tab);
  };

  const handleCustomWallpaperSubmit = async () => {
    const {
      preview,
      croppedAreaPixels,
      imgSrc,
      rotation,
      isWallpaperSpread,
      cropWallpaperImgData: cropCustomWallpaperImgData,
    } = customWallpaperState;

    dispatch(updateCustomWallpaperState({
      uploadingWallpaper: true
    }))

    const file = preview as File;
    const reader = new FileReader();
    const isCropped = croppedAreaPixels?.width && croppedAreaPixels?.height;

    if (isCropped || file) {
      reader.onloadend = async () => {
        const { name, size, type } = file;

        if (!reader.result) return;

        // fileType is the first param
        const [, extension] = type.split("/");

        const croppedImage =
          isCropped &&
          (await getCroppedImg(imgSrc, croppedAreaPixels as Area, rotation));

        // we always upload original and cropped images
        const formData: FormData = new FormData();
        formData.append("folder", "customer_wallpaper_prints");

        // original image
        formData.append("image", file);

        // cropped image
        if (croppedImage) {
          formData.append("image", croppedImage.blob, name);
        }

        const config = {
          method: "post",
          url: `${process.env.REACT_APP_SERVER_HOST}/upload/multiple`,
          data: formData,
          timeout: 180000,
        };

        function ensureHttps(link: string) {
          const httpRegex = /^http:\/\//;
          if (link.startsWith("https://")) {
            return link;
          }

          return link.replace(httpRegex, "https://");
        }

        try {
          const { data } = await axios(config);
          let images = data.urls;

          // ensure cloudinary links are https
          images = images.map((image: string) => ensureHttps(image));
          let image = images[0];

          // used to contain OG and cropped image
          let imageDetails: any = {
            original: image,
            isWallpaperSpread,
          };

          if (images.length === 2) {
            // use if cropped image exists
            image = images[1];
            imageDetails = { ...imageDetails, cropped: images[1] };
          }

          const _wallpaper = {
            name,
            size: cropCustomWallpaperImgData?.size
              ? cropCustomWallpaperImgData?.size
              : size,
            extension,
            data: image,
            details: JSON.stringify(imageDetails),
          };

          dispatch(updateCustomWallpaperState({
            wallpaper: _wallpaper,
            isCropUploaded: true
          }))

          enqueueSnackbar("Nahranie obrázku bolo úspešné", { variant: "success" });

          // handle print select and close dialog
          handleSelect(_wallpaper, tab);
        } catch (error) {
          enqueueSnackbar("Niekde nastala chyba počas nahrávania obrázka.", {
            variant: "error",
          });

          console.log(error);
        } finally {
          dispatch(updateCustomWallpaperState({
            uploadingWallpaper: false,
            imgSrc: "",
            crop: { x: 0, y: 0 }
          }))

        }
      };

      reader.readAsDataURL(file);
    }
  };

  const handleLocalWallpaperSubmit = () => {
    const { croppedAreaPixels, rotation, wallpaper, isWallpaperSpread } = localWallpaperState;

    // Use to generate cropped image in the admin
    // Which will be done with function: getCroppedImg
    const cropDetails = { croppedAreaPixels, rotation };

    const _wallpaper = {
      ...wallpaper,
      details: JSON.stringify({ cropDetails, isWallpaperSpread }),
    };

    // handle print select and close dialog
    handleSelect(_wallpaper, tab);
  };

  const baseWallpaperTabProps = {
    imgRef,
    previewCanvasRef,
    selectCroppedImage,
    selectedID,
  }

  const customWallpaperTabProps = {
    ...baseWallpaperTabProps,
    ...customWallpaperState,
  };

  const localWallpaperTabProps = {
    ...baseWallpaperTabProps,
    ...localWallpaperState,
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      onClose={closeDialog}
      open={open}
      maxWidth="xl"
      fullWidth={true}
      PaperComponent={Paper}
      sx={{
        "& .MuiDialog-paper": {
          padding: { xs: 4, md:8 },
          gap: 3,
        },
      }}
    >
      <DialogTitle
        sx={{
          color: "text.primary",
          p: 0,
          fontSize: { xs: "2rem", md: "2.5rem", textAlign: "center" },
        }}
      >
        Potlač
      </DialogTitle>

      <IconButton
        onClick={closeDialog}
        sx={{
          position: "absolute",
          top: "12px",
          right: "12px",
          color: "unset",
        }}
      >
        <Icon>close</Icon>
      </IconButton>

      <DialogContent
        sx={{
          p: 0,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <TabContext value={tab}>
          <TabSelector
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.label}
                disableRipple
                label={tab.label}
                value={tab.value}
              />
            ))}
          </TabSelector>

          <Box sx={{ width: "100%", minHeight: { xs: "unset", md: "50vh" } }}>
            <TabPanel
              value="color"
              sx={{ height: "100%", padding: { xs: "8px", md: "24px" } }}
            >
              <ColorPickerTab color={color} returnSetColor={setColor} />
              <Box display="flex" gap={1} mt={2} justifyContent="flex-end">
                <TabSubmitButton
                  variant="contained"
                  color="secondary"
                  onClick={handlePrintSelect}
                >
                  Vybrať
                </TabSubmitButton>
              </Box>
            </TabPanel>

            <TabPanel
              value="wallpaper"
              sx={{ height: "100%", padding: { xs: "8px", md: "24px" } }}
            >
              <WallpaperTab {...localWallpaperTabProps} />

              {!localWallpaperState.isCropMode && (
                <Box display="flex" gap={1} mt={2} justifyContent="flex-end">
                  <TabSubmitButton
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      // setIsLocalWallpapeCropMode(true)

                      dispatch(updateLocalWallpaperState({
                        isCropMode: true
                      }))
                    }}
                  >
                    Upraviť
                  </TabSubmitButton>

                  <TabSubmitButton
                    variant="contained"
                    color="secondary"
                    onClick={handleLocalWallpaperSubmit}
                  >
                    Vybrať
                  </TabSubmitButton>
                </Box>
              )}
            </TabPanel>

            <TabPanel value="ownWallpaper">
              <CustomWallpaperTab {...customWallpaperTabProps} />

              {customWallpaperState.uploadingWallpaper && <LinearProgress sx={{ my: 2 }} />}

              {!customWallpaperState.isCropMode && (
                <>
                  {!customWallpaperState.isCropUploaded && (
                    <Box
                      display="flex"
                      gap={1}
                      mt={2}
                      justifyContent="flex-end"
                    >
                      <TabSubmitButton
                        variant="outlined"
                        color="secondary"
                        disabled={customWallpaperState.uploadingWallpaper || !customWallpaperState.preview}
                        onClick={() => {
                          // setIsCustomWallpaperCropMode(true)

                          dispatch(updateCustomWallpaperState({
                            isCropMode: true
                          }))
                        }}
                      >
                        Späť
                      </TabSubmitButton>
                      <TabSubmitButton
                        variant="contained"
                        color="secondary"
                        onClick={handleCustomWallpaperSubmit}
                        disabled={customWallpaperState.uploadingWallpaper || !customWallpaperState.preview}
                      >
                        Vybrať
                      </TabSubmitButton>
                    </Box>
                  )}

                  {customWallpaperState.isCropUploaded && (
                    <Box
                      display="flex"
                      gap={1}
                      mt={2}
                      justifyContent="flex-end"
                    >
                      <TabSubmitButton
                        variant="outlined"
                        color="secondary"
                        disabled={customWallpaperState.uploadingWallpaper}
                        onClick={() => {
                          // setIsCustomWallpaperCropMode(true)

                          dispatch(updateCustomWallpaperState({
                            isCropMode: true
                          }))
                        }}
                      >
                        Späť
                      </TabSubmitButton>

                      <TabSubmitButton
                        variant="contained"
                        color="secondary"
                        onClick={handlePrintSelect}
                      >
                        Vybrať
                      </TabSubmitButton>
                    </Box>
                  )}
                </>
              )}
            </TabPanel>
          </Box>
        </TabContext>
      </DialogContent>
    </Dialog>
  );
};

export default PrintDialog;
