import * as React from "react";

import { Box, Icon, IconButton, Stack } from "@mui/material";
import { GridRowId, GridSelectionModel } from "@mui/x-data-grid";
import { Confirm } from "./components/confirmModal";

interface IToolbar {
  handleAddProduct: () => void;
  handleCopyProducts: (ids: GridRowId[]) => void;
  handleDeleteProducts: (ids: GridRowId[]) => void;
  selectedRows: GridSelectionModel;
}
export const TableToolbar = ({
  handleAddProduct,
  handleCopyProducts,
  handleDeleteProducts,
  selectedRows,
}: IToolbar) => {
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    React.useState(false);

  return (
    <Stack
      direction="row"
      id="fourth_demo_step"
      justifyContent="space-between"
      spacing={2}
      sx={{ py: 1, ml: 1, width: 'fit-content' }}
    >
      <Box>
        <IconButton
          onClick={(event) => {
            handleAddProduct();
          }}
        >
          <Icon>add</Icon>
        </IconButton>

        {selectedRows.length > 0 && (
          <>
            <IconButton
              onClick={(event) => {
                handleCopyProducts(selectedRows);
              }}
            >
              <Icon>content_copy</Icon>
            </IconButton>
            <IconButton
              onClick={(event) => {
                setDeleteConfirmationOpen(true);
              }}
            >
              <Icon>delete</Icon>
            </IconButton>
          </>
        )}
      </Box>
      <Confirm
        open={deleteConfirmationOpen}
        setOpen={setDeleteConfirmationOpen}
        header="Naozaj zmazať označené riadky?"
        // description="Produkty budu zmazane."
        confirmLabel="Ano, zmazať"
        cancelLabel="Nie"
        onConfirm={() => handleDeleteProducts(selectedRows)}
      />
    </Stack>
  );
};
