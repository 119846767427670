import { UserDataType } from "../../context/types";
import { ICustomer } from "../../models/ecomm/customer";
import { IProduct } from "../../models/ecomm/product";

// Calculate on sale product price
// used in product listings
export const calculateOnSaleDiscount = (
  product: IProduct,
  customer: ICustomer,
  user: UserDataType | null
) => {
  let discountedPrice = 0;
  if (!product.onSale) return discountedPrice;

  let discountAmount = product.saleDiscount; // unregistered customer discount

  if (user) {
    discountAmount = product.customerDiscount; // retail registered customer discount;

    if (customer && customer.isWholesaleCustomer) {
      discountAmount = product.wholesaleDiscount; // wholesale registered customer discount
    }
  }

  const originalPrice = product.price;
  const {value, prefix} = discountAmount;

  if (prefix === "%") {
    const _discountAmount = originalPrice * (value / 100);
    discountedPrice = originalPrice - _discountAmount;
  } else {
    discountedPrice = originalPrice - value;
  }

  return discountedPrice;
};

// calculat on sale product discount price
// used in order summary
export const calculateOnSaleDiscountValue = (
  product: IProduct,
  customer: ICustomer,
  user: UserDataType | null
) => {
  let discountedPrice = 0;
  if (!product.onSale) return discountedPrice;

  let discountAmount = product.saleDiscount; // unregistered customer discount

  if (user) {
    discountAmount = product.customerDiscount; // retail registered customer discount;

    if (customer && customer.isWholesaleCustomer) {
      discountAmount = product.wholesaleDiscount; // wholesale registered customer discount
    }
  }

  const originalPrice = product.price;
  const { value, prefix } = discountAmount;

  if (prefix === "%") {
    const _discountAmount = originalPrice * (value / 100);
    return _discountAmount
  }

  return discountAmount.value;
};

// used in order summary
export const calculateOnSaleDiscountAmount = (
  product: IProduct,
  customer: ICustomer,
  user: UserDataType | null
) => {
  if (!product.onSale) return "0%";

  let discountAmount = product.saleDiscount; // unregistered customer discount

  if (user) {
    discountAmount = product.customerDiscount; // retail registered customer discount;

    if (customer && customer.isWholesaleCustomer) {
      discountAmount = product.wholesaleDiscount; // wholesale registered customer discount
    }
  }

  const {value, prefix} = discountAmount
  return `${value}${prefix}`;
};

// Get customer discount amount
export const calculateCustomerDiscountAmount = (customer: ICustomer, user: UserDataType | null) => {
  if (!user && !customer.discount) return 0;

  const discountAmount = customer.discount.value;
  return discountAmount
}

// Calculate how much discount give customer
// applied to basket
export const calculateCustomerDiscount = (
  discountAmount: number,
  total: number,
  customer: ICustomer,
  user: UserDataType | null
) => {
  const {value, prefix} = customer.discount;
  if (!user && !value) return 0;

  let customerDiscount = discountAmount;

  if (prefix === "%") {
    customerDiscount = total * (discountAmount / 100);
  }

  return customerDiscount;
};
