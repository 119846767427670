import * as React from "react";

import {
  // DataGrid,
  skSK,
  GridActionsCellItem,
  GridRowId,
  GridRowParams,
  MuiEvent,
  GridCellEditCommitParams,
  GridValueFormatterParams,
  GridSelectionModel,
  GridRenderCellParams,
  GridPreProcessEditCellProps,
  GridColumns,
} from "@mui/x-data-grid";
import { Icon, Box, styled, Typography } from "@mui/material";

import { useSnackbar } from "notistack";
import chroma from "chroma-js";

import { useApp } from "../../../context/app-context";

import { IRowType } from "../../../models/ecomm/productTable";
import { IProduct } from "../../../models/ecomm/product";

import { calculatePPU } from "../../../helpers/ecomm/functions";
import {
  getMeterSquared,
  renderPrint,
  renderProduct,
} from "../../../helpers/ecomm/productFunctions";
import { findClosestOnyx } from "../../../helpers/onyxMatcher";

import { TableToolbar } from "./productTableToolbar";
import DataGrid from "../../dataGrid";

const TableWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  "& .MuiDataGrid-cell--editing": {
    backgroundColor: "rgb(255,215,115, 0.19)",
    color: "#1a3e72",
    "& .MuiInputBase-root": {
      height: "100%",
    },
  },
  "& .Mui-error": {
    backgroundColor: `rgb(126,10,15, ${
      theme.palette.mode === "dark" ? 0 : 0.1
    })`,
    color: theme.palette.error.main,
  },
}));

const StyledTypograpy = styled(Typography)(({ theme }) => ({
  width: "100%",
  // textAlign: "right"
}));


export const ProductTable = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { rows, updateAppContext, basketPrice } = useApp();

  const [lastRowId, setLastRowId] = React.useState<number>(rows.length);
  const [pageSize, setPageSize] = React.useState(25);
  const [selectionModel, setSelectionModel] =
    React.useState<GridSelectionModel>([]);

  // update last row id whenever rows are updated
  React.useEffect(() => {
    if (rows.length) {
      setLastRowId(rows.length);
    }
  }, [rows])

  const handleAddProduct = () => {
    const newProduct = {
      id: lastRowId + 1,
      name: "",
      product: null,
      print: {
        type: "color",
        value: {
          hex: "#f00",
          onyxMatch: findClosestOnyx(chroma("#F00").rgb()),
        },
      },
      glassWidth: 1000,
      glassHeight: 1000,
      description: "",
      qty: 1,
      ppu: 0,
      ppm2: 0,
      price: 0,
    };

    updateAppContext({
      rows: [...rows, newProduct],
    });
  };

  const handleDeleteProducts = () => {
    const updatedRows = rows.filter(
      (row: IRowType) => selectionModel.indexOf(row.id) === -1
    );

    updateAppContext({
      rows: updatedRows,
    });
  };

  // Used in product header
  const handleCopyProducts = () => {
    let copiedRows: IRowType[] = [];

    selectionModel.forEach(id => {
      let row = rows.find((row) => row.id === id) as IRowType;
      copiedRows.push(row);
    });

    let updatedRows = [...rows, ...copiedRows]

    // Update id so it's unique
    updatedRows = updatedRows.map((row, i) => ({
      ...row,
      id: i + 1
    }))

    updateAppContext({ rows: updatedRows });
    setSelectionModel([]); // unselect rows
    enqueueSnackbar("Produkty boli skopirované", { variant: "success" });
  };


  // Used in individual product row
  const handleCopyProduct = React.useCallback(
    (id: GridRowId) => () => {
      let row = rows.find((row) => row.id === id) as IRowType;
      let updatedRows = [...rows, row];

      // Update id so it's unique
      updatedRows = updatedRows.map((row, i) => ({
        ...row,
        id: i + 1,
      }));

      updateAppContext({ rows: updatedRows });
    },
    [updateAppContext, rows],
  );

  const columns: GridColumns<IRowType> = React.useMemo(
    () => [
      {
        field: "name",
        headerName: "Názov",
        width: 200,
        editable: true,
        renderCell: (params: GridRenderCellParams) => (
          <StyledTypograpy id="fifth_demo_step" variant="body2">
            {params.value}
          </StyledTypograpy>
        ),
      },
      {
        field: "product",
        headerName: "Produkt",
        width: 150,
        editable: true,
        renderCell: renderProduct,
        renderEditCell: renderProduct,
        align: "center",
        hideSortIcons: true,
      },
      {
        field: "glassHeight",
        headerName: "Výška",
        width: 125,
        type: "number",
        editable: true,
        preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
          const { row: _row } = params;
          const row = _row as IRowType;
          const { product } = row;
          const { minHeight, maxHeight } = product as IProduct;

          const interval: number[] = [minHeight, maxHeight];
          const hasError: boolean =
            (parseInt(params.props.value) as number) < Math.min(...interval) ||
            (parseInt(params.props.value) as number) > Math.max(...interval);

            if (hasError) {
              enqueueSnackbar(
                `Hodnota musí byť v intervale ${interval.join(" až ")}.`,
                { variant: "warning" }
              );
            }

          return { ...params.props, error: hasError };
        },
        renderCell: (params: GridRenderCellParams<number>) => {
          if (!params.value) return;

          return (
          <StyledTypograpy id="seventh_demo_step" variant="body2">
            {params.value.toFixed(0)} mm
          </StyledTypograpy>
        )},
      },
      {
        field: "glassWidth",
        headerName: "Šírka",
        width: 125,
        type: "number",
        editable: true,
        preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
          const { row: _row } = params;
          const row = _row as IRowType;
          const { product } = row;
          const { minWidth, maxWidth } = product as IProduct;

          const interval: number[] = [minWidth, maxWidth];
          const hasError: boolean =
            (parseInt(params.props.value) as number) < Math.min(...interval) ||
            (parseInt(params.props.value) as number) > Math.max(...interval);

            if (hasError) {
              enqueueSnackbar(
                `Hodnota musí byť v intervale ${interval.join(" až ")} mm.`,
                { variant: "warning" }
              );
            }

          return { ...params.props, error: hasError };
        },
        renderCell: (params: GridRenderCellParams<number>) => {
          if (!params.value) return;

          return (
          <StyledTypograpy id="seventh_demo_step" variant="body2">
            {params.value.toFixed(0)} mm
          </StyledTypograpy>
        )},
      },
      {
        field: "print",
        headerName: "Potlač",
        width: 100,
        editable: true,
        renderCell: renderPrint,
        renderEditCell: renderPrint,
        align: "center",
        hideSortIcons: true,
      },
      {
        field: "meterSquared",
        headerName: "Plocha",
        width: 125,
        type: "number",
        editable: false,
        valueGetter: getMeterSquared,
        renderCell: (params: GridRenderCellParams<number>) => (
          <Box
            component="div"
            alignItems="center"
            display="flex"
            justifyContent="center"
          >
            {params.value!.toFixed(2)} m<sup>2</sup>
          </Box>
        ),
      },
      {
        field: "ppu",
        headerName: "Cena za kus",
        width: 125,
        type: "number",
        editable: false,
        valueFormatter: (params: GridValueFormatterParams) => {
          if (params.value == null) {
            return "0 €";
          }
          return `${Number(params.value).toFixed(2)} €`;
        },
      },
      {
        field: "qty",
        headerName: "Počet kusov",
        width: 125,
        type: "number",
        editable: true,
        preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
          const interval: number[] = [1, 100];
          const hasError: boolean =
            (parseInt(params.props.value) as number) < Math.min(...interval) ||
            (parseInt(params.props.value) as number) > Math.max(...interval);
          if (hasError)
            enqueueSnackbar(
              `Hodnota musí byť v intervale ${interval.join(" až ")}.`,
              { variant: "warning" }
            );

          return { ...params.props, error: hasError };
        },
        renderCell: (params: GridRenderCellParams<number>) => {
          if (!params.value) return;

          return (
          <StyledTypograpy id="ninth_demo_step" variant="body2">
            {params.value}
          </StyledTypograpy>
        )},
      },
      {
        field: "price",
        headerName: "bez DPH",
        width: 150,
        type: "number",
        editable: false,
        valueFormatter: (params: GridValueFormatterParams<number>) => {
          if (params.value === 0) {
            return "0 €";
          }

          return `${Number(params.value).toFixed(2)} €`;
        },
      },
      {
        field: "priceDPH",
        headerName: "Celkom DPH",
        width: 150,
        type: "number",
        editable: false,
        renderCell: (params: GridRenderCellParams<number>) => {
          const { row } = params;
          const { price } = row;
          const { taxRate } = basketPrice;

          const taxAmount = (taxRate/100) * price
          const formatter = new Intl.NumberFormat("en", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });

          let priceWithDPH = price + taxAmount;
          priceWithDPH = formatter.format(priceWithDPH);

          return Number(priceWithDPH).toFixed(2);
        }
      },
      {
        field: "action",
        type: "actions",
        headerName: "Možnosti",
        width: 110,
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem
            icon={<Icon>content_copy</Icon>}
            id="tenth_demo_step"
            label="Kopírovať"
            onClick={handleCopyProduct(params.id)}
          />,
        ],
      },
    ],
    [enqueueSnackbar, basketPrice, handleCopyProduct]
  );


  return (
    <TableWrapper>
      <TableToolbar
        handleCopyProducts={handleCopyProducts}
        handleDeleteProducts={handleDeleteProducts}
        handleAddProduct={handleAddProduct}
        selectedRows={selectionModel}
      />

      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableSelectionOnClick={true}
          editMode="cell"
          localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
          pagination
          pageSize={pageSize}
          hideFooter={rows.length > 100 ? false : true}
          onPageSizeChange={(newPage) => setPageSize(newPage)}
          onCellEditCommit={(
            params: GridCellEditCommitParams,
            event: MuiEvent
          ) => {
            const { id, value, field } = params;

            updateAppContext({
              rows: rows.map((row: IRowType) => {
                let _row: IRowType = { ...row };

                if (row.id === id) {
                  _row[field] = value;

                  if (_row.product! === null) return _row;

                  _row.ppm2 = _row.product.price;

                  _row.ppu = calculatePPU(
                    _row.glassWidth,
                    _row.glassHeight,
                    _row.ppm2
                  );

                  _row.price = _row.ppu * _row.qty;
                }

                return _row;
              }),
            });
          }}
          checkboxSelection
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
          disableColumnFilter={true}
        />
      </div>
    </TableWrapper>
  );
};
