import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { FormInput } from "./formInput";
import { ErrorMessage, Formik, FormikHelpers } from "formik";
import { ConnectedFocusError } from "focus-formik-error";
import * as Yup from "yup";
import { CircularProgress } from "@mui/material";

const validationSchema = Yup.object().shape({
  email: Yup.string().required("Toto pole nesmie byť prázdne").email("Zadajte platný email"),
});

export type resetPasswordFormSchemaType = Yup.InferType<typeof validationSchema>;

const initialValues = {
  email: "",
} as resetPasswordFormSchemaType;

interface Props {
  handleSubmit: (
    values: resetPasswordFormSchemaType,
    formikHelpers: FormikHelpers<resetPasswordFormSchemaType>
  ) => void;
}

export const ResetPasswordForm = ({ handleSubmit }: Props): JSX.Element => {

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        errors,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit} style={{ width: "100%" }} noValidate>
          <ConnectedFocusError />

          <FormInput
            id="email"
            label="Emailova adresa"
            fullWidth
            name="email"
            placeholder="Zadaj svoj prihlasovací email"
            autoComplete="email"
            autoFocus
            value={values.email}
            onChange={handleChange}
          />

          {errors.email && (
            <Box mb={2} color="red">
              <ErrorMessage name="email" />
            </Box>
          )}

          <Box textAlign="center">
            <Button
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
              startIcon={
                isSubmitting ? (
                  <CircularProgress
                    style={{ color: "white", width: 22, height: 22 }}
                  />
                ) : null
              }
              sx={{
                mb: 2,
                borderRadius: "6px",
                fontSize: 16,
                fontWeight: "bold",
              }}
              disableElevation
            >
              Resetovať
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};
