import * as React from "react";
import { IFile, IRowType } from "../../../models/ecomm/productTable";
import { IBasketPrice } from "../../../models/ecomm/order";

import {
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemAvatar,
  Stack,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  Icon,
  AccordionDetails,
} from "@mui/material";

import { Print } from "../print/display-print";

import { useApp } from "../../../context/app-context";
import { ccyFormat } from "../../../helpers/ecomm/productFunctions";
import { useAuth } from "../../../hooks/useAuth";
import { calculateCustomerDiscount } from "../../../helpers/ecomm/calculateDiscount";

interface IOrderItemsSummary {
  options?: {
    summaryTextVariant:
      | "subtitle1"
      | "button"
      | "caption"
      | "h1"
      | "h2"
      | "h3"
      | "h4"
      | "h5"
      | "h6"
      | "inherit"
      | "subtitle2"
      | "body1"
      | "body2"
      | "overline"
      | undefined;
  };
  rowsProps?: IRowType[];
  basketProps?: IBasketPrice;
}

const listItemSpacing = {
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
};

export const OrderItemsSummary = ({
  options = { summaryTextVariant: "subtitle1" },
  rowsProps,
  basketProps,
}: IOrderItemsSummary) => {
  const { rows: rowsContext, basketPrice, customer } = useApp();
  const { user } = useAuth();

  const rows = rowsProps ?? rowsContext;
  const basket = basketProps ?? basketPrice;

  const [totalOnSaleDiscount, setTotalOnSaleDiscount] = React.useState(0);
  const [customerDiscount, setCustomerDiscount] = React.useState(0);

  // Calculate total on-sale discount
  React.useEffect(() => {
    if (!basket.appliedDiscounts) return;

    const onSaleDiscounts = basket.appliedDiscounts.onSale;

    if (!onSaleDiscounts.length) {
      setTotalOnSaleDiscount(0);
      return;
    }

    const totalDiscount = onSaleDiscounts.reduce((total, item) => {
      return Number(total + item.discount);
    }, 0);

    setTotalOnSaleDiscount(totalDiscount);
  }, [basket]);

  // Calculate customer discount
  React.useEffect(() => {
    if (!basket.appliedDiscounts) return;

    const _customerDiscount = calculateCustomerDiscount(
      basket.appliedDiscounts.customer.value,
      basket.subtotal,
      customer,
      user
    );

    setCustomerDiscount(_customerDiscount);
  }, [
    basket.appliedDiscounts,
    basket.appliedDiscounts.customer.value,
    basket.subtotal,
    customer,
    user,
  ]);

  return (
    <List disablePadding>
      {rows.map((row: IRowType, key) => {
        let isWallpaperSpread = false;

        const getIsWallpaperSpread = () => {
          if (row.print.type === "color") return;

          const value = row.print.value as IFile;

          const obj = JSON.parse(value.details as string);
          if (Object.hasOwn(obj, "isWallpaperSpread")) {
            isWallpaperSpread = obj.isWallpaperSpread;
          }
        };

        getIsWallpaperSpread();

        return (
          <ListItem key={key} sx={{ px: 0 }}>
            <ListItemAvatar>
              <Print print={row.print} readOnly isSummary />
            </ListItemAvatar>

            <ListItemText
              primary={
                <Stack direction="column">
                  <Typography variant="caption" sx={{ fontStyle: "italic" }}>
                    {row.name}
                  </Typography>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="body1">{row.product!.name}</Typography>
                    <Typography variant="body2" sx={{ fontWeight: 700 }}>
                      {`x ${row.qty}`}
                      <Typography
                        variant="caption"
                        component="span"
                        sx={{ fontWeight: "inherit" }}
                      >
                        {isWallpaperSpread ? " (Spread)" : ""}
                      </Typography>
                    </Typography>
                  </Stack>
                </Stack>
              }
              secondary={
                <Stack direction="column" spacing={0}>
                  <Typography sx={{ fontSize: ".8em" }}>
                    {`${row.glassWidth} x ${row.glassHeight} mm`}
                  </Typography>
                </Stack>
              }
            />

            <Stack direction="row" alignItems="baseline" spacing={3}>
              <Typography sx={{ fontSize: ".8em" }}>
                {(
                  ((row.glassWidth * row.glassHeight) / 1000000) *
                  row.qty
                ).toFixed(2)}{" "}
                m<sup>2</sup>
              </Typography>
              <Typography sx={{ fontSize: ".8em" }}>
                {`${(
                  Number(row.price) +
                  (Number(row.price) * basket.taxRate) / 100
                ).toFixed(2)}
                 €`}
              </Typography>
            </Stack>
          </ListItem>
        );
      })}

      <Divider sx={{ my: 2 }} />

      <ListItem sx={{ p: 0, flexDirection: "column" }}>
        <Box
          sx={{
            ...listItemSpacing,

            "& .MuiTypography-root": {
              fontSize: "0.725rem",
              color: "grey !important",
            },
          }}
        >
          <Typography variant={options.summaryTextVariant}>bez DPH</Typography>
          <Typography variant={options.summaryTextVariant}>
            {basket.subtotal.toFixed(2)} €
          </Typography>
        </Box>

        <Box
          sx={{
            ...listItemSpacing,
          }}
        >
          <Typography variant={options.summaryTextVariant} textAlign="right">
            Celkom DPH
          </Typography>
          <Typography variant={options.summaryTextVariant}>
            {ccyFormat(basket.subtotal + basket.tax)} €
          </Typography>
        </Box>
      </ListItem>

      <Divider sx={{ mt: 2 }} />

      {basket.appliedDiscounts &&
        (basket.appliedDiscounts.onSale.length > 0 ||
          basket.appliedDiscounts.customer.value > 0) && (
          <ListItem sx={{ p: 0 }}>
            <Accordion disableGutters sx={{ boxShadow: "none", width: "100%" }}>
              <AccordionSummary
                expandIcon={<Icon color="secondary">expand_circle_down</Icon>}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  p: 0,

                  "& .MuiAccordionSummary-content": {
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    mr: 1.5,
                  },
                }}
              >
                <Typography variant={options.summaryTextVariant}>
                  Zľavy
                </Typography>

                <Typography
                  variant={options.summaryTextVariant}
                  style={{ color: "red" }}
                >
                  -{ccyFormat(totalOnSaleDiscount + customerDiscount)} €
                </Typography>
              </AccordionSummary>

              <AccordionDetails sx={{ p: 0, mb: 1 }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  {basket.appliedDiscounts.onSale.length > 0 && (
                    <>
                      {basket.appliedDiscounts.onSale.map((item, ind) => {
                        const currentRow = rows.find(
                          (row) => row.product?._id === item.productID
                        );

                        if (!currentRow) return null;

                        const product = currentRow.product;
                        return (
                          <Box
                            key={ind}
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              py: 1,
                            }}
                          >
                            <Typography variant={options.summaryTextVariant}>
                              {product ? product.name : "Zľavový predaj:"}{" "}
                              <b>x {item.qty}</b>
                            </Typography>

                            <Typography
                              variant={options.summaryTextVariant}
                              sx={{ color: "red" }}
                            >
                              -{ccyFormat(item.discount)} €
                            </Typography>
                          </Box>
                        );
                      })}
                    </>
                  )}

                  {basket.appliedDiscounts.customer.value > 0 && (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant={options.summaryTextVariant}>
                        Zákaznícka zľava{" "}
                        <b>
                          (
                          {`${basket.appliedDiscounts.customer.value} ${basket.appliedDiscounts.customer.prefix}`}
                          )
                        </b>
                      </Typography>

                      <Typography
                        variant={options.summaryTextVariant}
                        sx={{ color: "red" }}
                      >
                        -{ccyFormat(customerDiscount)} €
                      </Typography>
                    </Box>
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          </ListItem>
        )}

      <Divider sx={{ mb: 1 }} />

      <ListItem sx={{ p: 0, flexDirection: "column" }}>
        <Box
          sx={{
            ...listItemSpacing,

            "& .MuiTypography-root": {
              fontSize: "0.725rem",
              color: "grey !important",
            },
          }}
        >
          <Typography variant={options.summaryTextVariant}>bez DPH</Typography>
          <Typography variant={options.summaryTextVariant}>
            {(
              basket.subtotal -
              (totalOnSaleDiscount + customerDiscount)
            ).toFixed(2)}{" "}
            €
          </Typography>
        </Box>

        <Box
          sx={{
            ...listItemSpacing,
            "& .MuiTypography-root": {
              color: (theme) => theme.palette.company.main,
              fontWeight: 500,
            },
          }}
        >
          <Typography variant={options.summaryTextVariant} textAlign="right">
            K uhrade
          </Typography>
          <Typography variant={options.summaryTextVariant}>
            {ccyFormat(basket.price)} €
          </Typography>
        </Box>
      </ListItem>
    </List>
  );
};
