import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useEffect } from "react";

import "./App.css";

import { Layout } from "./comps/layout";

import { AuthProvider } from "./context/auth-context";
import { DemoProvider } from "./context/demo-context";
import { EcommProvider } from "./context/ecomm-context";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallBack from "./screens/fallback";

import { AppRouteType, appRoutes } from "./routes";

// fixes scroll behaviour on route change
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const renderRoute = (route: AppRouteType, index: number): JSX.Element => {
  const useLayout = route.useLayout ?? true;
  const useEcomm = route.ecomm ?? false;

  let element = <route.element />;

  if (useEcomm) {
    element = <EcommProvider>{element}</EcommProvider>;
  }

  if (useLayout) {
    element = <Layout>{element}</Layout>;
  }

  return <Route key={index} path={route.to} element={element} />;
};

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <ErrorBoundary FallbackComponent={ErrorFallBack}>
        <DemoProvider>
          <AuthProvider>
            <Routes>
              {appRoutes.map((route, index) => renderRoute(route, index))}
            </Routes>
          </AuthProvider>
        </DemoProvider>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default App;
