import { Button } from "@mui/material";

const DownloadDataButton = () => {
  return (
    <Button
      disabled
      variant="outlined"
      sx={{
        textTransform: "none",
        color: "#344054",
        borderRadius: "8px",
        border: "1px solid #D0D5DD",
        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",

        "&:hover": {
          border: "1px solid #D0D5DD",
        },
      }}
    >
      Download My Data
    </Button>
  );
};

export default DownloadDataButton;
