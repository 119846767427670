import React, { useEffect } from "react";

import { LinearProgress } from "@mui/material";

import Cropper from "react-easy-crop";
import { Point } from "react-easy-crop/types";
import { useSnackbar } from "notistack";

import { ICrop } from "../../../../../../../models/ecomm/printTypes";
import { useDispatch } from "react-redux";
import { customWallpaperStateI, localWallpaperStateI, updateCustomWallpaperState, updateLocalWallpaperState } from "../../../redux";

interface Props
  extends ICrop,
    Partial<localWallpaperStateI & customWallpaperStateI> {
  file?: File;
  flip: {
    horizontal: boolean;
    vertical: boolean;
  };
  onCropComplete: (arg0: any, arg1: any) => void;
}

const CropBox = (props: Props) => {
  const {
    file,
    crop,
    imgSrc,
    zoom,
    onCropComplete,
    rotation,
    cropAspectRatio,
    flip,
    type,
  } = props;

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  // set crop image if file is a prop
  // And is custom wallpaper being cropped
  useEffect(() => {
    if (file && type === "custom") {
      dispatch(updateCustomWallpaperState({
        crop: { x: 0, y: 0 }
      }))

      const reader = new FileReader();
      reader.addEventListener("load", () => {
        dispatch(updateCustomWallpaperState({
          imgSrc: reader.result as string
        }))
      });

      reader.readAsDataURL(file);
    }

  }, [dispatch, enqueueSnackbar, file, type]);


  if (!imgSrc) return null;

  if (!imgSrc.length) {
    return <LinearProgress sx={{ mb: 2, width: 200 }} />;
  }

  const handleWallpaperUpdate = (data: any) => {
    if (type === "manual") {
      dispatch(updateLocalWallpaperState(data));
    } else {
      dispatch(updateCustomWallpaperState(data));
    }
  };

  const handleZoom = (zoom: number) => {
    handleWallpaperUpdate({ zoom });
  };

  const handleRotation = (rotation: number) => {
    handleWallpaperUpdate({ rotation });
  };

  const handleCrop = (crop: Point) => {
    handleWallpaperUpdate({ crop });
  };

  return (
      <Cropper
        image={imgSrc}
        transform={[
          `translate(${crop?.x}px, ${crop?.y}px)`,
          `rotateZ(${rotation}deg)`,
          `rotateY(${flip.horizontal ? 180 : 0}deg)`,
          `rotateX(${flip.vertical ? 180 : 0}deg)`,
          `scale(${zoom})`,
        ].join(" ")}
        crop={crop as Point}
        zoom={zoom}
        rotation={rotation}
        onZoomChange={handleZoom}
        aspect={cropAspectRatio}
        onCropChange={handleCrop}
        onCropComplete={onCropComplete}
        onRotationChange={handleRotation}
      />
  );
};


export default CropBox;
