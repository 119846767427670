import React from "react";
import axios from "axios";
import { IProduct } from "../models/ecomm/product";
import { useSnackbar } from "notistack";
import { useApp } from "./app-context";

interface IEcommContextValues {
  products: IProduct[];
  productsLoading: boolean;
}

const initialValues = {
  products: [],
  productsLoading: true,
};

const EcommContext = React.createContext<IEcommContextValues | null>(null);

export const useEcomm = () => {
  const ctx = React.useContext(EcommContext);

  if (!ctx) {
    throw new Error("Missing App context");
  }

  return {
    ...ctx,
  };
};

export const EcommProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [value, setValue] = React.useState<IEcommContextValues>(initialValues);
  const { basketPrice, updateAppContext } = useApp();

  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    const fetchProducts = async () => {
      if (value.products.length > 0) return;

      axios
        .get(
          `${process.env.REACT_APP_SERVER_HOST}/product/get-all-active?company=${process.env.REACT_APP_COMPANY_ID}`
        )
        .then((res) => {
          sessionStorage.setItem("products", JSON.stringify(res.data.products));

          setValue({
            products: res.data.products,
            productsLoading: false,
          });
        })
        .catch(() => {
          enqueueSnackbar("Niekde nastala chyba", { variant: "warning" });

          setValue({
            products: [],
            productsLoading: false,
          });
        });
    };

    fetchProducts();
  }, [enqueueSnackbar, value.products.length]);

  // Get tax
   React.useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_HOST}/company/get/${process.env.REACT_APP_COMPANY_ID}`,
        { withCredentials: true }
      )
      .then((res) => {
        const { company } = res.data;
        const _tax = company.taxRate;

        updateAppContext({
          basketPrice: {
            ...basketPrice,
            taxRate: _tax,
          },
        });
      });

     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EcommContext.Provider value={value}>{children}</EcommContext.Provider>
  );
};
