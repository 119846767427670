import { Box, Grid, Icon } from "@mui/material";

interface IContactInfoProps {
  title: string;
  description: string;
  contactInfo: { title: string; icon: string; detail: string }[];
}

export const ContactInfo = ({
  title,
  description,
  contactInfo,
}: IContactInfoProps) => {
  return (
    <Grid container direction="column" rowSpacing={2}>
      <Grid item>
        <Box
          sx={{
            fontSize: { xs: "htmlFontSize", sm: "h6.fontSize" },
            fontWeight: "medium",
          }}
        >
          {title}
        </Box>
      </Grid>
      <Grid sx={{ mb: 2 }} item>
        {description}
      </Grid>
      <Grid item>
        <Grid container direction="column" rowSpacing={1} columnSpacing={1}>
          {contactInfo.map((info) => (
            <Grid
              key={info.title}
              container
              item
              direction={{ xs: "column", sm: "row" }}
              alignItems="center"
            >
              <Icon sx={{ mr: 2, fontSize: "20px" }}>{info.icon}</Icon>
              <Box
                sx={{
                  fontSize: { xs: "default", sm: "htmlFontSize" },
                  fontWeight: "medium",
                }}
              >
                {info.title}:&nbsp;
              </Box>
              <Box
                sx={{
                  fontSize: { xs: "default", sm: "htmlFontSize" },
                  fontWeight: "regular",
                }}
              >
                {info.detail}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
