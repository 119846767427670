import { Button, ButtonProps, Icon } from "@mui/material";
import { Link } from "react-router-dom";

// typeof Link because of button has component={Link}
interface Props extends ButtonProps<typeof Link, {}> {
  children: string;
  to: string;
}

export const ThemeButton = (props: Props) => {
  const { children, to, ...rest } = props;

  return (
    <Button
    variant="outlined"
    size="large"
    sx={{
        color: "#005486",
        borderColor: "#005486",
        display: "flex",
        alignItems: "center",
        textTransform: "none",
        borderWidth: "0.145rem",
        "&:hover": {
          borderColor: "#005486",
          borderWidth: "0.145rem",
        },
      }}
      component={Link}
      to={to}
      {...rest}
    >
      {children}
      <Icon sx={{ ml: 2, mr: -1 }}>{"horizontal_rule"}</Icon>
      <Icon>{"arrow_forward"}</Icon>
    </Button>
  );
};
