import * as React from "react";

import { Box, Tab, styled } from "@mui/material";

import chroma from "chroma-js";
import { ColorResult } from "@uiw/color-convert";
import Colorful from "@uiw/react-color-colorful";

import { findClosestOnyx } from "../../../../../../helpers/onyxMatcher";

import { VariobelColor } from "../../../../../../models/ecomm/productTable";
import { TabContext } from "@mui/lab";
import { StyledTabSelector } from "./components/styled-tab-selector";
import { StyledTabPanel } from "./components/styled-tab-panel";
import { CustomColorTab } from "./components/custom-color-tab";
import CmykColorTab from "./components/cmyk-color-tab";
import HexColorTab from "./components/hex-color-tab";
import ColorSwatches from "./components/color-swatches";

interface Props {
  returnSetColor: (color: VariobelColor) => void;
  color: VariobelColor;
  dark?: boolean;
}

interface ITabs {
  label: string;
  value: string;
}

const tabs: ITabs[] = [
  {
    label: "Hex",
    value: "hex",
  },
  {
    label: "CMYK",
    value: "cmyk",
  },
  {
    label: "Vlastný výber",
    value: "custom",
  },
];

const colors: string[] = [
  "#EF4444",
  "#F97316",
  "#2DD4BF",
  "#6366F1",
  "#EC4899",
  "#D946EF",
  "#3B82F6",
  "#84CC16",
  "#FACC15",
  "#0EA5E9",
  "#F43F5E",
  "#00FF7F",
  "#FFBF46",
  "#7E2E84",
  "#58FCEC",
  "#303A2B",
  "#E98A15",
  "#145C9E",
  "#DCC7BE",
  "#DB5461",
  "#8338EC",
  "#FFBE0B",
  "#FB5607",
  "#000000",
  "#7FEFBD"
];

const Wrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  background: theme.palette.secondary.light,
  borderRadius: theme.spacing(1),
  border: "1px solid #D0D5DD",
  maxHeight: "450px",
  overflowY: "scroll"
}));

const ColorPickerTab = (props: Props) => {
  const { returnSetColor, color } = props;

  const [colorValue, setColorValue] = React.useState<VariobelColor>(color);

  const [colorHexField, setColorHexField] = React.useState(
    String(color.hex).replace("#", "")
  );

  const [colorCmykField, setColorCmykField] = React.useState<number[]>(
    chroma(String(color.hex)).cmyk()
  );

  const [tab, setTab] = React.useState("hex");

  const handleTabChange = (_: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  const handleColorChange = (color: ColorResult) => {
    setColorValue({
      hex: color.hex,
      onyxMatch: findClosestOnyx(chroma(color.hex).rgb()),
    });
    setColorHexField(String(color.hex).replace("#", ""));
    setColorCmykField(chroma(color.hex).cmyk());
    returnSetColor({
      hex: color.hex,
      onyxMatch: findClosestOnyx(chroma(color.hex).rgb()),
    });
  };

  const handleHexColorChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newColor: string = e.target.value;
    setColorHexField(newColor);
    if (chroma.valid(`#${newColor}`)) {
      setColorValue({
        hex: `#${newColor}`,
        onyxMatch: findClosestOnyx(chroma(`#${newColor}`).rgb()),
      });
      returnSetColor({
        hex: `#${newColor}`,
        onyxMatch: findClosestOnyx(chroma(`#${newColor}`).rgb()),
      });
    }
  };

  const handleCmykColorChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    i: number
  ) => {
    let colorCmykField_: number[] = [...colorCmykField];
    colorCmykField_[i] = Number(e.target.value) / 100;
    const [c, m, y, k] = [...colorCmykField_];
    setColorCmykField([c, m, y, k]);
    const hexValue: string = chroma.cmyk(c, m, y, k).hex();
    if (chroma.valid(hexValue)) {
      setColorValue({
        hex: hexValue,
        onyxMatch: findClosestOnyx(chroma(hexValue).rgb()),
      });
      setColorValue({
        hex: hexValue,
        onyxMatch: findClosestOnyx(chroma(hexValue).rgb()),
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column-reverse", md: "row" },
        gap: 1,
        minHeight: "450px",
        maxHeight: "450px",
      }}
    >
      <Wrapper
        sx={{
          flexBasis: "33%",
          display: "flex",
          flexDirection: { xs: "column-reverse", md: "column" },
        }}
      >
        <Box mt={{ xs: 1, md: 0 }}>
          <TabContext value={tab}>
            <StyledTabSelector
              variant="scrollable"
              scrollButtons="auto"
              onChange={handleTabChange}
            >
              {tabs.map((tab) => (
                <Tab
                  key={tab.label}
                  disableRipple
                  label={tab.label}
                  value={tab.value}
                />
              ))}
            </StyledTabSelector>

            <StyledTabPanel value="hex">
              <HexColorTab {...{ colorHexField, handleHexColorChange }} />
            </StyledTabPanel>

            <StyledTabPanel value="cmyk">
              <CmykColorTab {...{ colorCmykField, handleCmykColorChange }} />
            </StyledTabPanel>

            <StyledTabPanel value="custom">
              <CustomColorTab>
                <Colorful
                  disableAlpha
                  color={colorValue.hex}
                  onChange={handleColorChange}
                />
                <HexColorTab showLabel {...{ colorHexField, handleHexColorChange }} />
                <CmykColorTab showLabel {...{ colorCmykField, handleCmykColorChange }} />
              </CustomColorTab>
            </StyledTabPanel>
          </TabContext>
        </Box>

        <ColorSwatches
          colors={colors}
          color={colorValue.hex}
          onChange={handleColorChange}
        />
      </Wrapper>

      <Wrapper
        sx={{
          flex: 1,
        }}
      >
        <Box
          sx={{
            background: colorValue.hex,
            width: "100%",
            flex: 1,
            height: "100%",
            minHeight: 200,
            borderRadius: 1,
          }}
        />
      </Wrapper>
    </Box>
  );
};

export default ColorPickerTab;
