import { Paper, Tooltip } from "@mui/material";
import { IFile, VariobelColor } from "../../../models/ecomm/productTable";
import { ColoredChip } from "./components/coloredChip";

interface IPrint {
  print: { type: string; value: string | VariobelColor | IFile };
  readOnly?: boolean;
  isSummary?: boolean;
  onClick?: () => void;
}
export const Print = ({
  print,
  onClick = () => { },
  readOnly = false,
  isSummary =  false
}: IPrint) => {
  return (
    <>
      {print.type === "color" && (
        <>
          <Tooltip title="HEX">
            <ColoredChip
              sx={{
                borderRadius: "5px",
                cursor: readOnly && !isSummary ? "default" : "pointer",
                width: "34px",
                height: "34px",
                mr: 1
              }}
              disabled={readOnly && !isSummary}
              bgcolor={(print.value as VariobelColor).hex}
              onClick={onClick}
            />
          </Tooltip>

        </>
      )}

      {(print.type === "wallpaper" || print.type === "ownWallpaper") &&
        print.value && (
          <Paper
            variant="outlined"
            square
            component="img"
            src={
              (print.type as string) === "ownWallpaper"
                ? (print.value as IFile).data
                : (print.value as IFile).name
            }

            sx={{
              cursor: readOnly ? "default" : "pointer",
              borderRadius: "5px",
              width: "40px",
              height: "40px",
            }}
            onClick={onClick}
          />
        )}
    </>
  );
};
