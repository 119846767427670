import { useState } from "react";

import {
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  Box,
  Button,
  Alert,
  CircularProgress,
} from "@mui/material";

import { PageContainer } from "../../comps/pageContainer";
import { AuthPageWrapper } from "../../comps/login/authPageWrapper";
import { FormInput } from "../../comps/login/formInput";

import { ErrorMessage, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { useSnackbar } from "notistack";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Heslo je povinný údaj")
    .max(100, "Heslo ma príliž veľa znakov")
    .min(6, "Heslo ma príliž málo znakov"),
  confirmpass: Yup.string()
    .oneOf([Yup.ref("password"), null], "Zadané heslá sa nezhodujú")
    .required("Prosím zadajte znova svoje heslo"),
});

type schemaType = Yup.InferType<typeof validationSchema>;

const initialValues = {
  password: "",
  confirmpass: "",
} as schemaType;


const ForgotPasswordScreen = () => {
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [params] = useSearchParams();
  const token = params.get("token");
  const email = params.get("email");

  const handleSubmit = async (
    values: schemaType,
    formikHelpers: FormikHelpers<schemaType>
  ) => {
    const { password } = values;
    const { setSubmitting } = formikHelpers;

    const body = { token, email, password };

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_SERVER_HOST}/mail/verifyPasswordReset`,
      data: body,
    };

    try {
      await axios(config);
      enqueueSnackbar("Resetovanie hesla bolo úspešné", { variant: "success" });
      navigate("/prihlasenie");
    } catch (error) {
      const axiosError = (error as any).response;

      if (!axiosError.data) {
        return enqueueSnackbar("Niekde nastala chyba", { variant: "warning" });
      }

      if (axiosError.data.error === "TokenExpiredError") {
        enqueueSnackbar("Platnosť resetovania hesla vypršala", { variant: "warning" });
      } else if (axiosError.data.error === "SyntaxError") {
        enqueueSnackbar("Prosím kontaktujte podporu", { variant: "warning" });
      } else {
        Promise.reject(axiosError.data.error || error);
      }
    }

    setSubmitting(false);
  };


  if (!token || !email) {
    setTimeout(() => navigate('/'), 2000);

    return (
      <Box sx={{ width: { xs: "80%", md: "600px" }, margin: "auto", mt: 2 }}>
        <Alert
          severity="error"
          sx={{
            justifyContent: "center",
            alignItems: "center",
            "& .MuiAlert-action": {
              margin: 0,
              padding: 0,
              marginLeft: "12px",
            },
          }}
          action={<CircularProgress color="secondary" size={22} />}
        >
          Nedá sa overiť, vaša adresa URL je neúplná
        </Alert>
      </Box>
    );
  }

  return (
    <PageContainer>
      <AuthPageWrapper header="Forgot Password">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12}>
                  <FormInput
                    id="password"
                    label="Heslo"
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    autocomplete="new-password"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onMouseDown={(e) => e.preventDefault()}
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <Icon>visibility</Icon>
                          ) : (
                            <Icon>visibility_off</Icon>
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder="Vytvor heslo"
                    value={values.password}
                    onChange={handleChange}
                  />

                  {errors.password && (
                    <Box mb={1} color="red">
                      <ErrorMessage name="password" />
                    </Box>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <FormInput
                    id="confirmpass"
                    label="Potvrdenie hesla"
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    autocomplete="new-password"
                    autoComplete="new-password"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onMouseDown={(e) => e.preventDefault()}
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <Icon>visibility</Icon>
                          ) : (
                            <Icon>visibility_off</Icon>
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder="Zadaj heslo znova"
                    value={values.confirmpass}
                    onChange={handleChange}
                  />

                  {errors.confirmpass && (
                    <Box mb={1} color="red">
                      <ErrorMessage name="confirmpass" />
                    </Box>
                  )}
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={
                      isSubmitting ? (
                        <CircularProgress
                          style={{ color: "white", width: 22, height: 22 }}
                        />
                      ) : null
                    }
                    sx={{
                      borderRadius: "6px",
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                    disableElevation
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </AuthPageWrapper>
    </PageContainer>
  );
};

export default ForgotPasswordScreen;
