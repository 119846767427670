import { Chip, styled } from "@mui/material";
import chroma from "chroma-js";

const options = {
  // configure which props to exclude from DOM
  shouldForwardProp: (prop: string) => prop !== "hover",
};

export const ColoredChip = styled(
  Chip,
  options
)(({ bgcolor, hover = true }: { bgcolor: string, hover?: boolean; }) => ({
  color: bgcolor,
  backgroundColor: bgcolor,
  ":hover": {
    backgroundColor: hover ? chroma(bgcolor).brighten().hex() : bgcolor,
  },
  "&.Mui-disabled": {
    opacity: 1,
  },
}));
