import * as React from "react";
import Button from "@mui/material/Button";
import { Stack, Grid, Typography, GridProps } from "@mui/material";

import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import chroma from "chroma-js";

import { IProduct } from "../../models/ecomm/product";
import { IRowType } from "../../models/ecomm/productTable";

import { PageContainer } from "../../comps/pageContainer";
import { ProductTable } from "../../comps/ecomm/product/productTable";
import { ProductTableSummary } from "../../comps/ecomm/product/productTableSummary";
import { Product } from "../../comps/ecomm/product/product";

import { useApp } from "../../context/app-context";
import { useEcomm } from "../../context/ecomm-context";

import { findClosestOnyx } from "../../helpers/onyxMatcher";
import { calculatePPU } from "../../helpers/ecomm/functions";
import { updateProdcutPricing } from "../../helpers/ecomm/calculateProductPricing";
import { ProductSkeleton } from "../../comps/ecomm/product/productSkeleton";
import { useAuth } from "../../hooks/useAuth";

interface ProductListWrapperProps extends GridProps<any> {
  children: React.ReactNode
}

const Wrapper = ({ children }: { children: React.ReactNode }) => (
  <PageContainer sx={{ my: 2 }}>
    <Grid container spacing={3} sx={{ mb: 4 }}>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Stack
          direction="column"
          alignItems="center"
          sx={{ gap: 2, textAlign: "center" }}
        >
          <Typography
            variant="h4"
            color="secondary"
            fontWeight={600}
            sx={{
              fontSize: { xs: "1.65rem", md: "2.125rem" },
            }}
          >
            Začnite kliknutím na vybraný produkt
          </Typography>

          <Typography variant="body2" sx={{ fontSize: "0.85rem" }}>
            Pre pokračovanie prosím vyberte produkt, ktorý chcete si dať
            potlačiť. Výber produktu viete kedykoľvek zmeniť.
          </Typography>

          <Button component={Link} to="/faq" variant="contained">
            Otázky a odpovede
          </Button>
        </Stack>
      </Grid>
      {children}
    </Grid>
  </PageContainer>
);

const ProductListWrapper = ({ children }: ProductListWrapperProps) => (
  <Grid alignItems="center" item xl={3} md={4} xs={12}>
    {children}
  </Grid>
);

export const EcommScreen = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { rows, basketPrice, updateAppContext, customer } = useApp();
  const { enqueueSnackbar } = useSnackbar();
  const { products, productsLoading } = useEcomm();

  const [productList, setProductList] = React.useState<IProduct[]>([]);

  const canNextStep = () => {
    let ready = true;

    rows.forEach((row) => {
      if (
        row.product === null ||
        row.glassHeight === null ||
        row.glassWidth === null
      ) {
        ready = false;
      }
    });

    return ready;
  };

  const nextStep = () => {
    const emptyProductInCart = rows.filter((row: IRowType) => {
      return row.product === null;
    }).length;

    if (emptyProductInCart) {
      enqueueSnackbar(
        `Pred pokračovaním treba dokončiť konfiguráciu všetkých položiek.`,
        {
          variant: "warning",
        }
      );
      return;
    }

    sessionStorage.setItem("basketItems", JSON.stringify(rows));
    sessionStorage.setItem("basketPrice", JSON.stringify(basketPrice));

    navigate("dokoncit-objednavku", {
      replace: true,
      state: { rows: rows, basketPrice: basketPrice },
    });
  };

  const onProductSelect = (product: IProduct) => {
    const glassWidth = product.maxWidth;
    const glassHeight = product.maxHeight;
    const ppm2 = product.price;
    const qty = 1;

    const ppu = calculatePPU(glassWidth, glassHeight, ppm2);

    const price = ppu * qty;

    updateAppContext({
      rows: [
        {
          id: 1,
          name: "",
          product: product,
          print: {
            type: "color",
            value: {
              hex: "#f00",
              onyxMatch: findClosestOnyx(chroma("#F00").rgb()),
            },
          },
          glassWidth,
          glassHeight,
          description: String(product.description),
          qty,
          ppu,
          ppm2,
          price,
        },
      ],
    });
  };

  React.useEffect(() => {
    if (!productsLoading) {
      const _products = products.map((product) =>
        updateProdcutPricing(product, customer, user)
      );
      setProductList(_products);
    }
  }, [productsLoading, products, customer, user]);


  // products in cart
  if (rows.length > 0) {
  return (
    <PageContainer sx={{ my: 2 }}>
      <Stack
        sx={{ my: 4 }}
        direction="column"
        spacing={3}
        alignItems="flex-end"
      >
        <ProductTable />
        <ProductTableSummary />
        <Button
          variant="contained"
          size="large"
          color="company"
          sx={{ color: "white" }}
          disabled={!canNextStep()}
          onClick={nextStep}
        >
          Dokončiť objednávku
        </Button>
      </Stack>
    </PageContainer>
  );
  }

  // products loading
  if (productsLoading) {
    return (
      <Wrapper>
        {Array(8)
          .fill(false)
          .map((_, i) => (
            <ProductListWrapper key={i}>
              <ProductSkeleton />
            </ProductListWrapper>
          ))}
      </Wrapper>
    );
  }

  // nothing in cart
  if (rows.length === 0 && productList.length > 0) {
    return (
      <Wrapper>
        {productList.map((product: IProduct, i) => {
          return (
            <ProductListWrapper key={i}>
              <div id={i === 1 ? "third_demo_step" : ""}>
                <Product
                  product={product}
                  onSelect={() => onProductSelect(product)}
                />
              </div>
            </ProductListWrapper>
          );
        })}
      </Wrapper>
    );
  }

  // no products
  return (
    <Wrapper>
      <Grid item xs={12} sx={{ display: "grid", placeItems: "center"}}>
        <p>Nothing to display</p>
      </Grid>
    </Wrapper>
  )
};
