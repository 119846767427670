import React from "react";
import ReactDOM from "react-dom/client";
import type {} from '@mui/x-data-grid/themeAugmentation';
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { SnackbarProvider } from "notistack";

import reportWebVitals from "./reportWebVitals";
import { AppProvider } from "./context/app-context";

import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

// extension to allow custom color
declare module '@mui/material/styles' {
  interface Palette {
    company: Palette['primary'];
  }

  interface PaletteOptions {
    company?: PaletteOptions['primary'];
  }
}

// extension to allow custom color in button
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    company: true;
  }
}

const theme = createTheme({
  typography: {
    fontFamily: "Poppins",

    h1: {
      color: "#005486",
    },
    h2: {
      color: "#005486",
    },
    h3: {
      color: "#005486",
    },
    h4: {
      color: "#005486",
    },
    h5: {
      color: "#005486",
    },
    h6: {
      color: "#005486",
    },

    body1: {
      color: "#1C1B1F"
    },
    body2: {
      color: "#1C1B1F"
    },
    overline: {
      color: "#1C1B1F"
    },
    subtitle1: {
      color: "#1C1B1F"
    },
    subtitle2: {
      color: "#1C1B1F"
    },
    caption: {
      color: "#1C1B1F"
    },
    button: {
      color: "#1C1B1F"
    }
  },

  palette: {
    primary: {
      light: "#777",
      main: "#222522",
      dark: "#111511",
    },
    secondary: {
      main: "#005486",
      light: "#f7f7f7",
      contrastText: "#000000",
    },
    company: {
      main: "#F4A600"
    }
  },

  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          background: "#f7f7f7",
          borderRadius: "8px",
          border: "none",

          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },

          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },

          "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
            padding: "0 20px",

            "&:focus, :focus-within": {
              outline: "none",
            },
          },

          "& .MuiDataGrid-columnHeaderDraggableContainer, .MuiDataGrid-columnHeaderTitleContainer":
            {
              gap: "8px",
            },

          "& .MuiDataGrid-columnHeader--sorted": {
            backgroundColor: "#C6D2D8",

            "& .MuiDataGrid-iconButtonContainer": {
              borderRadius: "100px",
              background: "#B2C0C7",
            },
          },

          "& .MuiDataGrid-columnHeaderCheckbox, .MuiDataGrid-cellCheckbox": {
            minWidth: "90px !important",
            maxWidth: "90px !important",
            width: "90px !important"
          }

        }
      }
    },
  },
});

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppProvider>
        <SnackbarProvider maxSnack={10}>
          <App />
        </SnackbarProvider>
      </AppProvider>
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
