import { Navigate, useNavigate } from "react-router-dom";
import { useRef, useState, useEffect } from "react";

import { Button } from "@mui/material";
import { FormikProps } from "formik";
import axios from "axios";

import OrderReview from "../../comps/ecomm/checkout/order-review";
import { Stepper } from "../../comps/stepper";
import ShippingDetails from "../../comps/ecomm/checkout/shipping-details";

import {
  IFile,
  IRowType,
  VariobelColor,
} from "../../models/ecomm/productTable";
import { IOrderCreate, IOrderResponse } from "../../models/ecomm/order";
import { ICustomer } from "../../models/ecomm/customer";

import { useApp } from "../../context/app-context";
import { useAuth } from "../../hooks/useAuth";

export const CheckoutScreen = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [step, setStep] = useState(0);

  const addressFormRef = useRef<FormikProps<ICustomer>>(null);
  const { user } = useAuth();
  const navigate = useNavigate();
  const { rows, basketPrice, customer, updateAppContext } = useApp();

  const onNextAddressForm = async () => {
    if (!addressFormRef.current) return false;

    const isValid = await addressFormRef.current.validateForm().then((res) => {
      return Object.keys(res).length === 0;
    });

    return isValid;
  };

  const clear = () => {
    sessionStorage.removeItem("rows");
    sessionStorage.removeItem("basket");
    updateAppContext({
      rows: [],
    });
  };

  const order: IOrderCreate = {
    // source: process.env.REACT_APP_LANG_SOURCE as string,
    company: process.env.REACT_APP_COMPANY_ID as string,
    customerId: customer["_id"],
    lineItems: rows.map((row: IRowType) => {
      const { print } = row;
      let updatedPrintValue = print.value;

      if (print.type === "color") {
        updatedPrintValue = JSON.stringify(print.value as VariobelColor);
      }

      // updated print value to have a string
      // needs to be object in other places except here
      if (print.type === "ownWallpaper") {
        updatedPrintValue = String((print.value as IFile).details);
      }

      if (print.type === "wallpaper") {
        const value = print.value as IFile;
        const data = {
          file: value.name,
          ...JSON.parse(value.details as string),
        };

        updatedPrintValue = JSON.stringify(data);
      }

      return {
        ...row,
        print: {
          type: row.print.type,
          value: updatedPrintValue,
        },
        name: row.name,
        productId: row.product!["_id"],
      };
    }),

    basketPrice,
  };

  const sendEmail = (orderResp: { order: IOrderResponse }) => {
    const { orderID } = orderResp.order;
    const recipient = customer.email;

    axios
      .post(`${process.env.REACT_APP_SERVER_HOST}/order-status/send-email`, {
        orderID,
        recipient,
      })
      .catch(function (error: any) {
        Promise.reject(error);
      });
  };

  const completeOrder = () => {
    setIsSubmitting(true);

    if (!user) {
      order.customer = customer;
    }

    axios
      .post(`${process.env.REACT_APP_SERVER_HOST}/order/create`, order, {
        withCredentials: true,
      })
      .then((res: any) => {
        setIsSubmitting(false);

        navigate("/objednavka-odoslana", {
          replace: true,
          state: {
            ...res.data,
            rowsProp: rows,
          },
        });

        clear();
        sendEmail({ ...res.data });
      })
      .catch(function (error: any) {
        setIsSubmitting(false);
        Promise.reject(error);
      });
  };

  // if User, route directly to order summary
  useEffect(() => {
    if (user) {
      setStep(1);
    }
  }, [user]);

  if (basketPrice.price === 0) {
    return (
      <Navigate
        to={{
          pathname: "/",
        }}
      />
    );
  }

  return (
    <>
      <Stepper
        step={step}
        setStep={setStep}
        onFinish={completeOrder}
        maxWidth={{ stepper: "md", content: "md", footer: "md" }}
        isSubmitting={isSubmitting}
        goBack={
          <Button
            variant="outlined"
            onClick={() => {
              navigate("/obchod", {
                replace: true,
              });
            }}
          >
            Upraviť objednávku
          </Button>
        }
        steps={[
          {
            label: "Údaje zákazníka",
            component: <ShippingDetails ref={addressFormRef} />,
            onNext: onNextAddressForm,
          },
          {
            label: "Súhrn objednávky",
            component: <OrderReview />,
          },
        ]}
      />
    </>
  );
};
