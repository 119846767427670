import { useState, MouseEvent, forwardRef } from 'react'
import { Box, Container, Toolbar, IconButton, Menu, MenuItem, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import ProfileIcon from './profileIcon';
import ShoppingCart from './shoppingCart';
import { useSnackbar } from 'notistack';

interface Props {
  navItems: {
    title: string;
    path: string;
  }[];
}

const Topbar = forwardRef((props: Props, ref: any) => {
  const { navItems } = props;
  const [profileAnchor, setProfileAnchor] = useState<null | HTMLElement>(null);
  const [loggingOut, setLoggingOut] = useState(false);

  const { logout, user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const openProfile = Boolean(profileAnchor);
  const handleProfileClose = () => setProfileAnchor(null);

  const handleProfileClick = (event: MouseEvent<HTMLElement>) => {
    setProfileAnchor(event.currentTarget);
  };

  const handleLogout = async () => {
    setLoggingOut(true);

    await logout()
      .then(() => {
        enqueueSnackbar(`Odhlásenie bolo úspešné!`, { variant: "success" });
      })
      .catch(() => {
        enqueueSnackbar(`Odhlásenie zlyhalo!`, { variant: "warning" });
      });

    setLoggingOut(false);
    handleProfileClose();
  };


  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#fff",
        color: "#555",
      }}
    >
      <Container component="nav" maxWidth="xl">
        <Toolbar ref={ref}>
          <Box sx={{ flexGrow: 1 }} />

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton component={Link} to="/obchod">
              <ShoppingCart />
            </IconButton>

            <IconButton
              onClick={handleProfileClick}
              aria-controls={openProfile ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openProfile ? "true" : undefined}
            >
              <ProfileIcon />
            </IconButton>

            {/* profile nav */}
            <Menu
              anchorEl={profileAnchor}
              id="account-menu"
              open={openProfile}
              onClose={handleProfileClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },

                  "& .MuiMenuItem-root": {
                    marginX: 1,
                    borderRadius: "4px",
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              {!user && (
                <div>
                  {navItems.map((item) => (
                    <MenuItem
                      key={item.path}
                      component={Link}
                      to={item.path}
                      onClick={handleProfileClose}
                    >
                      {item.title}
                    </MenuItem>
                  ))}
                </div>
              )}

              {user && (
                <div>
                  <MenuItem
                    component={Link}
                    to="/profile"
                    onClick={handleProfileClose}
                    sx={{ marginX: 1, borderRadius: "4px" }}
                  >
                    Profile
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      Logout
                      {loggingOut && (
                        <CircularProgress
                          color="secondary"
                          sx={{
                            width: "20px !important",
                            height: "20px !important",
                          }}
                        />
                      )}
                    </Box>
                  </MenuItem>
                </div>
              )}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </Box>
  );
})

export default Topbar