import React from "react";
import { Container, ContainerProps } from "@mui/material";

interface Props extends ContainerProps {
  children: JSX.Element;
}

export const PageContainer: React.FC<Props> = (props) => {
  const { children, ...rest } = props;

  return (
    <Container sx={{ my:3 }} maxWidth="xl" {...rest}>
      {children}
    </Container>
  );
};
