import chroma from "chroma-js";
import { findClosestOnyx } from "../../../../helpers/onyxMatcher";
import { IFile, PrintTabSelectorType, VariobelColor } from "../../../../models/ecomm/productTable";
import { importAllImages } from "../../../../helpers/ecomm/productFunctions";

export const getLocalWallpapers = () => {
  const _localWallpapers: IFile[] = importAllImages(
    require.context("../../../../assets/wallpapers/", false, /\.(png|jpe?g)$/)
  );

  const localWallpapers = _localWallpapers.sort((a, b) => Number(a.order) - Number(b.order));
  return localWallpapers;
};


export const getColorFromProp = (selectedTab: PrintTabSelectorType, selectedValue: string | VariobelColor | IFile) => {
  if(selectedTab === "color") {
    return selectedValue as VariobelColor
  }

  return {
    hex: "ff0",
    onyxMatch: findClosestOnyx(chroma("#ff0").rgb())
  }
};

export const getCustomWallpaperFromProp = (selectedTab: PrintTabSelectorType, selectedValue: string | VariobelColor | IFile) => {
  if (selectedTab !== 'ownWallpaper') return null;

  return selectedValue as IFile;
}

export const getLocalWallpaperFromProp = (selectedTab: PrintTabSelectorType, selectedValue: string | VariobelColor | IFile, localWallpapers: IFile[]) => {
  if (selectedTab !== 'wallpaper') {
    return localWallpapers[0];

  }
  return selectedValue as IFile;
}
