import { FormControl, InputBase, InputLabel } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledInput = styled(InputBase)(() => ({
  "label + &": {
    marginTop: "24px",

    borderRadius: 8,
    border: "1px solid #dbdbdb",
    fontSize: 16,
    width: "100%",
  },
  
  "& .MuiInputBase-input": {
    padding: "15px",
  },

  "& .MuiIconButton-root": {
    marginRight: 0
  }
}));

interface IFormInputProps {
  label: string;

  defaultValue?: any;
  id: string;

  [inputProps: string]: any;
}

export const FormInput: React.FC<IFormInputProps> = ({
  id,
  label,

  defaultValue,
  ...rest
}) => {
  return (
    <FormControl sx={{ width: "100%", marginBottom: 2 }} variant="standard">
      <InputLabel
        sx={{
          fontSize: 16,
          fontWeight: 700,
          textTransform: "uppercase",
          color: "#000",
        }}
        shrink
        htmlFor={id}
      >
        {label}
      </InputLabel>
      <StyledInput defaultValue={defaultValue}  id={id} {...rest} />
    </FormControl>
  );
};
