import * as React from "react";
import { Stack, Skeleton } from "@mui/material";

export const ProductSkeleton = () => (
  <Stack direction="column" justifyContent="center" sx={{ mt: 2 }}>
    <Skeleton variant="rectangular" height={250} />
    <Skeleton variant="text" />
    <Skeleton variant="text" width={300} />
    <Skeleton variant="text" width={100} />
    <Skeleton variant="text" />
    <Skeleton
      variant="circular"
      sx={{ justifyContent: "center" }}
      width={40}
      height={40} />
  </Stack>
);
