import { useState, useEffect } from "react";
import { Badge, Icon } from "@mui/material";
import { useApp } from "../../context/app-context";

const ShoppingCart = () => {
  const [basketItems, setBasketItems] = useState(0);

  const { rows } = useApp();

  useEffect(() => {
    setBasketItems(rows.length);
  }, [rows]);

  return (
      <Badge
        badgeContent={basketItems}
        color="error"
        sx={{
          "& .MuiBadge-badge": {
            fontSize: { xs: "0.5rem", sm: "0.75rem" },
            padding: { xs: "0 3px", sm: "0 6px" },
            minWidth: { xs: "16px", sm: "20px" },
            height: { xs: "16px", sm: "20px" },
          },
        }}
      >
        <Icon
          sx={{
            fontSize: "1.5rem",
            color: "#383637",
          }}
        >
          shopping_bag
        </Icon>
      </Badge>
  );
};

export default ShoppingCart;
