import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { FormInput } from "./formInput";
import { ErrorMessage, Formik, FormikHelpers } from "formik";
import { ConnectedFocusError } from "focus-formik-error";
import * as Yup from "yup";
import { InputAdornment, IconButton ,Icon, CircularProgress } from "@mui/material";
import { useState } from "react";

const LoginFormSchema = Yup.object().shape({
  email: Yup.string().required("Toto pole nesmie byť prázdne").email("Zadajte platný email"),
  password: Yup.string()
    .required("Toto pole nesmie byť prázdne")
    .max(100, "Toto pole musí mať najviac 100 znakov")
    .min(6, "Toto pole musí mať aspoň 6 znakov"),
});

const initialValues = {
  email: "",
  password: "",
};

interface ILoginFormProps {
  handleSubmit: (
    {
      email,
      password,
    }: {
      email: string;
      password: string;
    },
    {
      setStatus,
      setSubmitting,
    }: FormikHelpers<{
      email: string;
      password: string;
    }>
  ) => void;
}

export const LoginForm = ({ handleSubmit }: ILoginFormProps): JSX.Element => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={LoginFormSchema}
      onSubmit={handleSubmit}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        errors,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit} style={{ width: "100%" }} noValidate>
          <ConnectedFocusError />

          <FormInput
            id="email"
            label="Emailova adresa"
            fullWidth
            name="email"
            placeholder="Zadaj svoj prihlasovací email"
            autoComplete="email"
            autoFocus
            value={values.email}
            onChange={handleChange}
          />

          {errors.email && (
            <Box mb={2} color="red">
              <ErrorMessage name="email" />
            </Box>
          )}

          <FormInput
            id="password"
            label="Heslo"
            fullWidth
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <Icon>visibility</Icon>
                  ) : (
                    <Icon>visibility_off</Icon>
                  )}
                </IconButton>
              </InputAdornment>
            }
            name="password"
            placeholder="Your password"
            autoComplete="current-password"
            value={values.password}
            onChange={handleChange}
          />

          {errors.password && (
            <Box mb={2} color="red">
              <ErrorMessage name="password" />
            </Box>
          )}

          <Box textAlign="center">
            <Button
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
              startIcon={
                isSubmitting ? (
                  <CircularProgress
                    style={{ color: "white", width: 22, height: 22 }}
                  />
                ) : null
              }
              sx={{
                mb: 2,
                borderRadius: "6px",
                fontSize: 16,
                fontWeight: "bold",
              }}
              disableElevation
            >
              Pihlásiť
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};
