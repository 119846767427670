import React from "react";

import { IRowType } from "../models/ecomm/productTable";
import { IBasketPrice } from "../models/ecomm/order";
import { useState } from "../helpers/state";
import { ICustomer } from "../models/ecomm/customer";

interface IAppContext {
  rows: IRowType[];
  basketPrice: IBasketPrice;
  customer: ICustomer;
  errors: string[];
  updateAppContext: (states: {
    rows?: IRowType[];
    basketPrice?: IBasketPrice;
    customer?: ICustomer;
    errors?: string[];
  }) => void;
}

const AppContext = React.createContext<IAppContext | null>(null);

export const useApp = () => {
  const ctx = React.useContext(AppContext);

  if (!ctx) {
    throw new Error("Missing App context");
  }

  return {
    ...ctx,
  };
};

export const initialAppValues = {
  rows: [],
  basketPrice: {
    price: 0,
    tax: 0,
    subtotal: 0,
    taxRate: 0,
    qtyTotal: 0,
    appliedDiscounts: {
      onSale: [],
      customer: {
        value: 0,
        prefix: "%",
      },
      order: {
        value: 0,
        prefix: "%",
      },
    },
  },
  customer: {
    _id: "",

    billingAddress: [
      {
        company: "",
        address: "",
        city: "",
        postcode: "",
        country: {
          code: "SK",
          label: "Slovakia",
          phone: "421",
        },
      },
    ],

    firstName: "",
    lastName: "",
    isWholesaleCustomer: false,

    email: "",
    phone: "",
    isRegistered: false,
    password: "",
    useShippingAddress: false,
    gdprConsent: false,
    marketingConsent: false,

    company: {
      dic: "",
      icdph: "",
      ico: "",
      name: "",
    },

    discount: {
      value: 0,
      prefix: "%",
    },
  },
};

export const AppProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const {
    state: { rows, basketPrice, customer, errors },
    updateState,
  } = useState<{
    rows: IRowType[];
    basketPrice: IBasketPrice;
    customer: ICustomer;
    errors: string[];
  }>({
    rows: sessionStorage.getItem("basketItems")
      ? JSON.parse(sessionStorage.getItem("basketItems") as string)
      : initialAppValues.rows,

    basketPrice: sessionStorage.getItem("basketPrice")
      ? JSON.parse(sessionStorage.getItem("basketPrice") as string)
      : initialAppValues.basketPrice,

    customer: sessionStorage.getItem("userData")
      ? JSON.parse(sessionStorage.getItem("userData") as string)
      : initialAppValues.customer,
    errors: [],
  });

  return (
    <AppContext.Provider
      value={{
        rows,
        basketPrice,
        customer,
        errors,
        updateAppContext: updateState,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
