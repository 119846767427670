import { useCallback, useEffect } from "react";
import { Step } from "react-joyride";

const useDemoScrollIntoView = (
  steps: Step[],
  stepIndex: number,
  run: boolean
) => {
  const scrollToElement = useCallback((element: Element) => {
    const scrollIntoViewOptions: ScrollIntoViewOptions = {
      behavior: "smooth",
      block: "center",
    };

    element.scrollIntoView(scrollIntoViewOptions);
  }, []);

  useEffect(() => {
    // stepIndex can equal steps.length because its needed to end the demo tour
    // It is a conditional here because there will be no step based on that stepIndex
    if (stepIndex < steps.length && run) {
      const step = steps[stepIndex];
      const selector = step.target as string;
      const element = document.querySelector(selector);

      if (element) {
        scrollToElement(element);
      }
    }
  }, [run, scrollToElement, stepIndex, steps]);
};

export default useDemoScrollIntoView;
