import { Typography, Box } from "@mui/material";

interface IAuthPageHeaderProps {
  header: string;
}

export const AuthPageHeader: React.FC<IAuthPageHeaderProps> = ({ header }) => {
  return (
    <Box>
      <Typography
        variant="h4"
        sx={{
          mb: 2,
          fontWeight: 700,
          textAlign: "center",
        }}
      >
        {header}
      </Typography>
    </Box>
  );
};
