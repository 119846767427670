import { Box, CircularProgress, ClickAwayListener, Container, Icon, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";

import { useAuth } from "../../hooks/useAuth";

import ProfileIcon from "./profileIcon";
import ShoppingCart from "./shoppingCart";

interface NavItemType {
  title: string;
  path: string;
  onClick?: () => void;
  icon?: boolean;
};

interface Props {
  navItems: NavItemType[];
  noAuthNavItems: NavItemType[];
}

const MobileNav = (props: Props) => {
  const {  navItems, noAuthNavItems } = props;

  const [mobileOpen, setMobileOpen] = useState(false);
  const toggleMobileNav = () => setMobileOpen(prev => !prev);
  const [displayedNavItems, setDisplayedNavItems] = useState(navItems);
  const [loggingOut, setLoggingOut] = useState(false);

  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const closeMobileNav = () => setMobileOpen(false);

  const handleClickAway = () => {
    if (!mobileOpen) return;

    closeMobileNav()
  };

  const onMenuClick = () => {
    toggleMobileNav();
    setDisplayedNavItems(navItems);
  }

  const handleLogout = async () => {
    setLoggingOut(true);

    await logout()
      .then(() => {
        enqueueSnackbar(`Odhlásenie bolo úspešné!`, { variant: "success" });
      })
      .catch(() => {
        enqueueSnackbar(`Odhlásenie zlyhalo!`, { variant: "warning" });
      });

    setLoggingOut(false);
  };

  const onProfileClick = () => {
    toggleMobileNav();

    // profile nav items
    if(!user) {
      setDisplayedNavItems(noAuthNavItems);
    } else {
      const items = [
        { title: "Profile", path: "/profile" },
        { title: "Logout", path: "#", icon: true, onClick: handleLogout }
      ];

      setDisplayedNavItems(items);
    }
  }

  const handleNavItemClick = async (item: NavItemType) => {
    if (item.onClick) {
      await item.onClick();
      return setMobileOpen(false);
    }

    setMobileOpen(false);
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Container
        component="nav"
        maxWidth="sm"
        sx={{
          backgroundColor: "#fff",
          position: "fixed",
          boxShadow: "0 1px 0 0 rgb(0 0 0 / 6%)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 2,
          }}
        >
          <Typography component={Link} to="/" py={1.5} onClick={closeMobileNav}>
            <Box component="img" sx={{ width: "60%" }} src="/logo.svg" />
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1.25,
              "& .MuiIconButton-root": {
                p: 0,
              },
            }}
          >
            <IconButton component={Link} to="/obchod" onClick={closeMobileNav}>
              <ShoppingCart />
            </IconButton>

            <IconButton onClick={onProfileClick}>
              <ProfileIcon />
            </IconButton>

            <IconButton onClick={onMenuClick} disableRipple>
              <Icon
                sx={{
                  color: "#383637",
                  fontSize: "2rem",
                }}
              >
                {mobileOpen ? "close" : "menu"}
              </Icon>
            </IconButton>
          </Box>
        </Box>

        <Box
          sx={{
            overflow: "hidden",
            height: mobileOpen ? 160 : 0,
            transition: `height 150ms linear`,

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {displayedNavItems.map((item) => (
            <Typography
              key={item.title}
              component={Link}
              to={item.path}
              onClick={() => handleNavItemClick(item)}
              sx={{
                textDecoration: "none",
                color: "#383637",
                fontWeight: 600,
                fontSize: "1rem",
                textAlign: "center",
                py: 1,
                px: 3,
                display: "flex",
                alignItems: "center",
                gap: 1
              }}
            >
              {item.title}
              {item.icon && loggingOut && (
                <CircularProgress
                  color="secondary"
                  sx={{
                    width: "20px !important",
                    height: "20px !important",
                  }}
                />
              )}
            </Typography>
          ))}
        </Box>
      </Container>
    </ClickAwayListener>
  );
};

export default MobileNav;
