import * as React from "react";
import { Box } from "@mui/system";
import { Footer } from "./footer";
import { Button, Container, Stack, Typography } from "@mui/material";
import CookieConsent from "react-cookie-consent";
import { NavBar } from "./navbar";

export const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <NavBar />

      <Box
        component="main"
        sx={{
          display: "flex",
          flex: "1 0 auto",
          mb: 5,
          mt: { xs: 2, sm: 0 }
        }}
      >
        <Container maxWidth="xl">{children}</Container>
      </Box>

      <CookieConsent
        location="bottom"
        buttonText="Súhlasím"
        declineButtonText="Nesúhlasím"
        cookieName="cookie_suhlas"
        style={{
          background: "#222522",
          paddingLeft: "25px",
          paddingRight: "25px",
        }}
        ButtonComponent={Button}
        disableButtonStyles={true}
        enableDeclineButton={true}
        declineButtonStyle={{
          color: "#fff",
          backgroundColor: "#d32f2f",
          fontSize: "13px",
          marginRight: "10px",
        }}
        buttonStyle={{
          color: "#222522",
          backgroundColor: "#f4a600",
          fontSize: "13px",
        }}
        expires={150}
      >
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" spacing={3}>
            <Typography component="span" sx={{ fontSize: "32px", color: "white" }}>
              🍪
            </Typography>
            <Typography component="span" sx={{ fontSize: "14px", color: "white" }}>
              Táto stránka používa súbory "cookie" pre zlepšenie online zážitku.
              Analyzujeme návštevnosť, prispôsobujeme reklamu a použivateľské
              nastavenia.
            </Typography>
          </Stack>
        </Container>
      </CookieConsent>
      <Footer />
    </Box>
  );
};
