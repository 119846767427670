// ** React Imports
import { createContext, useEffect, useState, ReactNode } from "react";
import {  createSearchParams, useLocation, useNavigate, useSearchParams } from "react-router-dom";

// ** Axios
import axios from "axios";

// ** Config
import authConfig from "../configs/auth";

// ** Types
import {
  AuthValuesType,
  RegisterParams,
  LoginParams,
  ErrCallbackType,
  UserDataType,
} from "./types";
import { initialAppValues, useApp } from "./app-context";

// ** Defaults
const defaultProvider: AuthValuesType = {
  user: null,
  loading: true,
  setUser: () => null,
  setLoading: () => Boolean,
  isInitialized: false,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  setIsInitialized: () => Boolean,
  register: () => Promise.resolve(),
  deleteUser: () => Promise.resolve(),
  editUser: () => Promise.resolve()
};

const AuthContext = createContext(defaultProvider);

type Props = {
  children: ReactNode;
};

const AuthProvider = ({ children }: Props) => {
  // ** States
  const [user, setUser] = useState<UserDataType | null>(defaultProvider.user);
  const [loading, setLoading] = useState<boolean>(defaultProvider.loading);
  const [isInitialized, setIsInitialized] = useState<boolean>(
    defaultProvider.isInitialized
  );

  const { updateAppContext } = useApp();

  // ** Hooks
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    setIsInitialized(true);
    const storedUser = sessionStorage.getItem("userData");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
    setLoading(false);
  }, []);

  // REROUTE IF NOT AUTHENICATED
  useEffect(() => {
    const storedUser = sessionStorage.getItem("userData");

    const protectedRoutes = [
      "/profile",
    ];

    const authRoutes = [
      "/prihlasenie",
      "/registracia",
      "/verify"
    ]

    // reroute if not authenticated
    protectedRoutes.forEach((route) => {
      if (location.pathname === route && !storedUser) {
        alert("Please sign in");
        navigate({
          pathname: "/prihlasenie",
          search: createSearchParams({
            returnUrl: location.pathname,
          }).toString(),
        },
        { replace: true });
      }
    });

    // reroute if already authenticated
    authRoutes.forEach((route) => {
      if (location.pathname === route && storedUser) {
        console.log(location.pathname, route)
        alert("Already signed in");
        navigate("/");
      }
    });
  }, [location, navigate])

  const handleLogin = async (
    params: LoginParams,
    errorCallback?: ErrCallbackType
  ) => {
    await axios
      .post(authConfig.loginEndpoint, params, { withCredentials: true })
      .then((res) => {
        const returnUrl = searchParams.get("returnUrl");

        setUser({ ...res.data.userData });
        updateAppContext({customer: { ...res.data.userData } })

        sessionStorage.setItem(
          "userData",
          JSON.stringify(res.data.userData)
        );

        const redirectURL = returnUrl && returnUrl !== "/" ? returnUrl : "/";

        navigate(redirectURL, { replace: true });
      })
      .catch((err) => {
        if (errorCallback) errorCallback(err);
      });
  };

  const clearAll = () => {
    sessionStorage.clear();
    updateAppContext({ ...initialAppValues })
  }

  const handleLogout = async () => {
    await axios
      .post(authConfig.logoutEndpoint, {}, { withCredentials: true })
      .then((res) => {
        setUser(null);
        setIsInitialized(false);
        clearAll()
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteUser = (id: string) => {
    axios
      .delete(
        `${process.env.REACT_APP_SERVER_HOST}/customer/delete/${id}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        console.log("profile delete success");
        setUser(null);
        setIsInitialized(false);
        clearAll()
        navigate("/");
      })
      .catch((err) => console.log("error deleting profile", err));
  }

  // TODO replace any type with actual schema
  const handleEditUser = async(id:string, data: any) => {
    await axios.patch(
      `${process.env.REACT_APP_SERVER_HOST}/customer/update/${id}`,
      data,
      {
        withCredentials: true,
      }
    )
    .then((res) => {
      setUser({ ...res.data.customer });
      updateAppContext({customer: { ...res.data.customer } })

      sessionStorage.setItem(
        "userData",
        JSON.stringify(res.data.customer)
      );
    })
    .catch((err) => Promise.reject(err));
  }

  const handleRegister = async (
    params: RegisterParams,
    resolveCallback?: () => void,
    errorCallback?: ErrCallbackType
  ) => {
    await axios
      .post(authConfig.registerEndpoint, params)
      .then(() => {
        if (resolveCallback) resolveCallback();
      })
      .catch((err) => {
        console.log(err);
        if (errorCallback) errorCallback(err);
      }
      );
  };

  const values = {
    user,
    loading,
    setUser,
    setLoading,
    isInitialized,
    setIsInitialized,
    login: handleLogin,
    logout: handleLogout,
    register: handleRegister,
    deleteUser: handleDeleteUser,
    editUser: handleEditUser
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
