import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { Alert, Box, Button, CircularProgress } from "@mui/material";
import { sendCustomerEmaiVerification } from "./signup";
import { useSnackbar } from "notistack";

const VerifyCustomerScreen = () => {
  const [verifying, setVerifying] = useState(true);
  const [success, setSuccess] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [canResend, setCanResend] = useState(false);
  const [resending, setResending] = useState(false);

  const [params] = useSearchParams();
  const token = params.get("token");
  const email = params.get("email");

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const resendVerification = async () => {
    if (!email) return;

    setResending(true);
    await sendCustomerEmaiVerification(email)
      .then((res) => {
        if (res.status === 200) {
          enqueueSnackbar("Email bol odoslaný: Prosím skontrolujte doručenú poštu", { variant: "success", autoHideDuration: 2000 });
        } else {
          enqueueSnackbar("Niekde nastala chyba, prosím kontaktujte podporu", { variant: "error", autoHideDuration: 2000 });
        }
      })
      .finally(() => setResending(false));
  };

  const ResendAction = () => {
    if (canResend) {
      return (
        <Button
          variant="contained"
          disabled={resending}
          onClick={resendVerification}
        >
          Resend
          {resending && <CircularProgress size={12} sx={{ ml: 1}} />}
        </Button>
      );
    }

    return null;
  }

  // verification process
  useEffect(() => {
    const getCustomerEmaiVerification = async (
      token: string,
      email: string
    ) => {
      const config = {
        method: "get",
        url: `${process.env.REACT_APP_SERVER_HOST}/mail/verifyCustomer?token=${token}&email=${email}`,
      };

      try {
        await axios(config);
        setSuccess(true);
      } catch (error) {
        setSuccess(false);

        const { data } = (error as any).response;

        if (data.error === "TokenExpiredError") {
          setCanResend(true);
          setAlertMessage("Verification has expired");
        } else if (data.error === "SyntaxError") {
          setAlertMessage("Contact Support")
        } else {
          setAlertMessage(data.error)
        }
      }
    };

    const verify = async () => {
      if (token && email) {
        setVerifying(true);
        await getCustomerEmaiVerification(token, email).finally(() =>
          setVerifying(false)
        );
      }
    };

    verify();
  }, [token, email]);

  // verification is successful
  useEffect(() => {
    if (success) {
      navigate('/prihlasenie')
    }
  }, [navigate, success])

  if (!token || !email) {
    setTimeout(() => navigate('/'), 2000);

    return (
      <Box sx={{ width: { xs: "80%", md: "600px" }, margin: "auto", mt: 2 }}>
        <Alert
          severity="error"
          sx={{
            justifyContent: "center",
            alignItems: "center",
            "& .MuiAlert-action": {
              margin: 0,
              padding: 0,
              marginLeft: "12px",
            },
          }}
          action={<CircularProgress color="secondary" size={22} />}
        >
          Can't be verified, your URL is incomplete
        </Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ width: { xs: "80%", md: "600px" }, margin: "auto", mt: 2 }}>
      {verifying && (
        <Alert
          severity="info"
          sx={{
            justifyContent: "center",
            alignItems: "center",
            "& .MuiAlert-action": {
              margin: 0,
              padding: 0,
              marginLeft: "12px",
            },
          }}
          action={<CircularProgress color="secondary" size={22} />}
        >
          Verifying...
        </Alert>
      )}

      {!verifying && (
        <>
          {!success && (
            <Alert
              severity="error"
              sx={{
                justifyContent: "center",
                alignItems: "center",
                "& .MuiAlert-action": {
                  margin: 0,
                  padding: 0,
                  marginLeft: "12px",
                },
              }}
              action={<ResendAction />}
            >
              Error: {alertMessage}
            </Alert>
          )}

          {success && (
            <Alert
              severity="success"
              sx={{
                justifyContent: "center",
                alignItems: "center",
                "& .MuiAlert-action": {
                  margin: 0,
                  padding: 0,
                  marginLeft: "12px",
                },
              }}
              action={<CircularProgress color="secondary" size={22} />}
            >
              Successful. Redirecting...
            </Alert>
          )}
        </>
      )}
    </Box>
  );
};

export default VerifyCustomerScreen;
