import { Tabs as MuiTabs, TabsProps } from "@mui/material";

const DesktopTabs = (props: TabsProps) => {
  return (
    <MuiTabs
      sx={{
        display: { xs: "none", sm: "flex" },
        flex: 1,
        height: "74px",

        "& .MuiTab-root": {
          textTransform: "none",
          p: "0px 20px",
          height: "74px",
        },

        "& .MuiTabs-flexContainer": {
          zIndex: 1,
          position: "absolute",
        },

        "& .MuiTabs-indicator": {
          backgroundColor: { xs: "transparent", sm: "#C6D2D8"},
          height: "100%",
        },
      }}
    {...props}
    />
  );
}

export default DesktopTabs;